import { MainFooter, MainHeader } from "../Common/Common";
import { useDispatch } from "react-redux";
import { changeHeaderStyle } from "../../store/store";
import { useEffect, useState } from "react";
import { TopCarousel } from "../MainPage/MainPage";
import { TopCarouselData, Jobsfeedintro1Data, Jobsfeedintro2Data, JobsfeedThemaData } from "./JobsfeedPageData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUser, faBuilding, faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";

import './JobsfeedPage.css';
import { Link } from "react-router-dom";


const JobsfeedPage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(1));
  },[])

  return(
    <>
      <MainHeader/>
      <TopCarousel TopCarouselData={TopCarouselData}/>
      <JobsfeedPositionBanner/>
      <JobsfeedAIPositionBanner/>
      <JobsfeedCheckBanner/>
      <JobsfeedProfileMacth/>
      <Jobsfeedintro1/>
      <Jobsfeedintro2/>
      <div className="jobsfeed-divider main-container"></div>
      <JobsfeedThema/>
      <div className="jobsfeed-divider main-container"></div>
      <JobsfeedNewPosition/>
      <MainFooter/>
    </>
  )
}

const JobsfeedPositionBanner =()=>{
  return(
    <div className="main-container">
      <Link to='/jobslist'>
        <div className="recommend-job-banner-back">
          <div>
            <FontAwesomeIcon icon={faSearch} className='jobsfeed-position-banner-icon'/> 
            <span>채용 중인 포지션 보러가기 </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

const JobsfeedAIPositionBanner=()=>{
  return(
    <div className="jobsfeed-ai-position-back flex-column">
      <div className="jobsfeed-ai-position-logo">
        <img className="jobsfeed-ai-position-img1" src="/assets/img/aiposition-logo.png"/>
        <img aria-label="wantedai-logo" src="https://static.wanted.co.kr/images/ai/logo-wantedai.png" width="170" alt="logo-wantedai"/>
      </div>
      <div className="jobsfeed-ai-position-title flex-row">
        <img className="wantedai-logo" aria-label="wantedai-logo" src="https://static.wanted.co.kr/images/ai/logo-wantedai.png" width="170" alt="logo-wantedai"/>
        <span>가 제안하는 합격률 높은 포지션</span>
        <button style={{marginLeft:"6px"}} type="button" data-attribute-id="position__aiScore__click" aria-label="wanted-ai button"><svg width="24" height="24" viewBox="0 0 17 17"><defs><filter id="bfoh3u0w3a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.600000 0 0 0 0 0.600000 0 0 0 0 0.600000 0 0 0 1.000000 0"></feColorMatrix></filter></defs><g fill="none" fillRule="evenodd"><g><g><g transform="translate(-1080 -374) translate(1080 374)"><g><path stroke="#999" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M9.421 13.334c-.736.277-1.535.43-2.368.43-3.706 0-6.71-3.005-6.71-6.711 0-3.707 3.004-6.71 6.71-6.71 1.853 0 3.53.75 4.745 1.965 1.214 1.214 1.965 2.892 1.965 4.745 0 1.853-.75 3.53-1.965 4.745" transform="translate(1 1)"></path><path fill="#999" d="M6.382 10.408c0-.371.3-.671.67-.671.371 0 .672.3.672.67 0 .372-.3.672-.671.672-.37 0-.671-.3-.671-.671" transform="translate(1 1) rotate(-180 7.053 10.408)"></path><path stroke="#999" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M5.04 5.655c0-1.08.901-1.958 2.013-1.958 1.11 0 2.013.877 2.013 1.958 0 1.08-1.007 1.957-2.013 1.957v.783" transform="translate(1 1)"></path></g></g></g></g></g></svg></button>
      </div>
      <div className="jobsfeed-ai-position-sub-title">회원가입하면 포지션을 추천해드려요.</div>
      <button className="jobsfeed-ai-position-btn">지금 시작하기</button>
    </div>
  )
}

const JobsfeedCheckBanner=()=>{
  return(
    <div className="jobsfeed-type-test-banner-back main-container flex-row">
      <div style={{marginTop:"6px"}}>내 연차에 이 연봉 괜찮은가? 궁금할 땐</div>
      <img src="https://static.wanted.co.kr/career_connect/gomin.png" alt="career-connect-icon"></img>
      <button>커리어 중간점검</button>
    </div>
  )
}

const JobsfeedProfileMacth=()=>{
  return(
    <div className="jobsfeed-profile-match-back main-container flex-row">
      <div className="jobsfeed-profile-match-box profile-box">
        <FontAwesomeIcon icon={faUser} className='icon-style'/>
        <button>내 프로필</button>
      </div>
      <div className="jobsfeed-profile-match-box match-box">
        <FontAwesomeIcon icon={faBuilding} className='icon-style'/>
        <button>매치업</button>
      </div>
    </div>
  )
}

const Jobsfeedintro1=()=>{

  // 버튼 스타일
  const [CarouselBtn, setcarouselBtn] = useState(["disabled-btn","abled-btn"]);
  // 캐러샐 움직이기
  const [BannerPx, setBannerPx] = useState(0);
  const MoveBtn = (type)=>{
    if(type==1 && BannerPx==0){
      setBannerPx(-1060);
      setcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==1 && BannerPx==-1060){
      setBannerPx(-1060*2);
      setcarouselBtn(['abled-btn','disabled-btn']);
    }
    if(type==-1 && BannerPx==-1060*2){
      setBannerPx(-1060);
      setcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==-1 && BannerPx==-1060){
      setBannerPx(0);
      setcarouselBtn(['disabled-btn','abled-btn']);
    }
  };


  // className={`event-banner-btn ${eventCarouselBtn[0]}`} onClick={()=>{eventMoveBtn(-1);}}

  return(
    <div className="intro-banner-back main-container">
      <div className="intro-banner-title-box flex-row">
        <button className={`intro-banner-btn ${CarouselBtn[0]}`} onClick={()=>{MoveBtn(-1);}}  >
          <FontAwesomeIcon icon={faChevronLeft}/>
        </button>
        <div>
          <div className="intro-banner-title flex-row">
            <span>#일한만큼 받는 보상</span>
            <img src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Ftags%2F472333e8-9f6e-11ec-b909-0242ac120002.png&w=75&q=75"/>
            <span>회사를 소개합니다</span>
          </div>
          <Link to='/' className="intro-banner-sub-title">
            <span>포지션으로 더보기</span>
            <FontAwesomeIcon icon={faChevronRight}  className="icon-style" style={{fontSize:"13px"}}/>
          </Link>
        </div>
        <button className={`intro-banner-btn ${CarouselBtn[1]}`} onClick={()=>{MoveBtn(1);}}>
          <FontAwesomeIcon icon={faChevronRight}/>
        </button>
      </div>
      <div className="intro-banner-swiper-back">
        <div className="intro-banner-swiper-contents flex-row" style={{transform:`translateX(${BannerPx}px)`}}>
          {
            Jobsfeedintro1Data.map((data)=>{
              return(
                <div className="intro-banner-card" key={data.index}>
                  <img src={data.mainImg}/>
                  <div className="intro-banner-company-info flex-row">
                    <div className="intro-banner-company-info-left flex-row">
                      <img src={data.subImg}/>
                      <div className="intro-banner-company-text-box">
                        <div>{data.name}</div>
                        <div>{data.tags}</div>
                      </div>
                    </div>
                    <button className="intro-banner-company-info-follow">팔로우</button>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

const Jobsfeedintro2=()=>{

  // 버튼 스타일
  const [CarouselBtn, setcarouselBtn] = useState(["disabled-btn","abled-btn"]);
  // 캐러샐 움직이기
  const [BannerPx, setBannerPx] = useState(0);
  const MoveBtn = (type)=>{
    if(type==1 && BannerPx==0){
      setBannerPx(-1060);
      setcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==1 && BannerPx==-1060){
      setBannerPx(-1060*2);
      setcarouselBtn(['abled-btn','disabled-btn']);
    }
    if(type==-1 && BannerPx==-1060*2){
      setBannerPx(-1060);
      setcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==-1 && BannerPx==-1060){
      setBannerPx(0);
      setcarouselBtn(['disabled-btn','abled-btn']);
    }
  };


  return(
    <div className="intro-banner-back main-container">
      <div className="intro-banner-title-box flex-row">
        <button className={`intro-banner-btn ${CarouselBtn[0]}`} onClick={()=>{MoveBtn(-1);}}>
          <FontAwesomeIcon icon={faChevronLeft}/>
        </button>
        <div>
          <div className="intro-banner-title flex-row">
            <span>#연봉이 최고의 복지</span>
            <img src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Ftags%2F634f02e0-9f6e-11ec-b909-0242ac120002.png&w=75&q=75"/>
            <span>회사를 소개합니다</span>
          </div>
          <Link to='/' className="intro-banner-sub-title">
            <span>포지션으로 더보기</span>
            <FontAwesomeIcon icon={faChevronRight}  className="icon-style" style={{fontSize:"13px"}}/>
          </Link>
        </div>
        <button className={`intro-banner-btn ${CarouselBtn[1]}`} onClick={()=>{MoveBtn(1);}}>
          <FontAwesomeIcon icon={faChevronRight}/>
        </button>
      </div>
      <div className="intro-banner-swiper-back">
        <div className="intro-banner-swiper-contents flex-row" style={{transform:`translateX(${BannerPx}px)`}}>
          {
            Jobsfeedintro2Data.map((data)=>{
              return(
                <div className="intro-banner-card" key={data.index}>
                  <img src={data.mainImg}/>
                  <div className="intro-banner-company-info flex-row">
                    <div className="intro-banner-company-info-left flex-row">
                      <img src={data.subImg}/>
                      <div className="intro-banner-company-text-box">
                        <div>{data.name}</div>
                        <div>{data.tags}</div>
                      </div>
                    </div>
                    <button className="intro-banner-company-info-follow">팔로우</button>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

const JobsfeedThema=()=>{

  // 버튼 스타일
  const [CarouselBtn, setcarouselBtn] = useState(["disabled-btn","abled-btn"]);
  // 캐러샐 움직이기
  const [BannerPx, setBannerPx] = useState(0);
  const MoveBtn = (type)=>{
    if(type==1 && BannerPx==0){
      setBannerPx(-1060);
      setcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==1 && BannerPx==-1060){
      setBannerPx(-1060*2);
      setcarouselBtn(['abled-btn','disabled-btn']);
    }
    if(type==-1 && BannerPx==-1060*2){
      setBannerPx(-1060);
      setcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==-1 && BannerPx==-1060){
      setBannerPx(0);
      setcarouselBtn(['disabled-btn','abled-btn']);
    }
  };



  return(
    <div className="intro-banner-back main-container">
      <div className="intro-banner-title-box flex-row">
        <button className={`intro-banner-btn ${CarouselBtn[0]}`} onClick={()=>{MoveBtn(-1);}}>
          <FontAwesomeIcon icon={faChevronLeft}/>
        </button>
        <div>
          <div className="intro-banner-title flex-row">
            <span>테마로 모아보는 요즘 채용</span>
            <span></span>
          </div>
        </div>
        <button className={`intro-banner-btn ${CarouselBtn[1]}`} onClick={()=>{MoveBtn(1);}}>
          <FontAwesomeIcon icon={faChevronRight}/>
        </button>
      </div>
      <div className="intro-banner-swiper-back">
        <div className="intro-banner-swiper-contents flex-row" style={{transform:`translateX(${BannerPx}px)`}}>
          {
            JobsfeedThemaData.map((data)=>{
              return(
                <div className="intro-banner-card" key={data.index}>
                  <img src={data.mainImg}/>
                  <div className="intro-banner-company-info flex-row">
                    <div className="intro-banner-company-info-left flex-row">
                      <div className="intro-banner-company-text-box">
                        <div>{data.title}</div>
                        <div>{data.subTitle}</div>
                        <div className="flex-row thema-company-logos">
                          <img style={{width:`${data.subImgLength}`}} src={data.subImg}/>
                          {
                            data.add !=null? <button>{data.add}</button> : null
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }

          {/* {
            Jobsfeedintro2Data.map((data)=>{
              return(
                <div className="intro-banner-card" key={data.index}>
                  <img src={data.mainImg}/>
                  <div className="intro-banner-company-info flex-row">
                    <div className="intro-banner-company-info-left flex-row">
                      <div className="intro-banner-company-text-box">
                        <div>{data.name}</div>
                        <div>{data.tags}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          } */}
        </div>
      </div>
    </div>
  )
}

const JobsfeedNewPosition=()=>{

  library.add(farBookmark);

  return(
    <div className="jobsfeed-new-position-back main-container">
      <div className="jobsfeed-new-position-title">요즘 뜨는 포지션</div>
      <div className="flex-row">
        <div className="jobsfeed-new-position-card">
          <img src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F13307%2Fo8vpozkmjfthdk2y__400_400.png&w=400&q=75"/>
          <div>프로덕트 오너 (PO / PM / 서비스 기획)</div>
          <div>피클플러스</div>
          <div>서울 · 한국 </div>
          <div>채용보상금 1,000,000원</div>
          <button className="jobsfeed-bookmark-btn">
            <FontAwesomeIcon icon={farBookmark}/>
          </button>
        </div>
        <div className="jobsfeed-new-position-card">
          <img src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F5380%2Fvd2jueavxoxdrb5l__400_400.jpg&w=400&q=75"/>
          <div>웹디자이너</div>
          <div>모던하우스(MH&Co.)</div>
          <div>서울 · 한국 </div>
          <div>채용보상금 1,000,000원</div>
          <button className="jobsfeed-bookmark-btn">
            <FontAwesomeIcon icon={farBookmark}/>
          </button>
        </div>
        <div className="jobsfeed-new-position-card">
          <img src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F7111%2Fydhlckrrli3dyd3i__400_400.jpg&w=400&q=75"/>
          <div>[미니창고 다락] 인사 운영 매니저</div>
          <div>세컨신드롬</div>
          <div>서울 · 한국 </div>
          <div>채용보상금 1,000,000원</div>
          <button className="jobsfeed-bookmark-btn">
            <FontAwesomeIcon icon={farBookmark}/>
          </button>
        </div>
        <div className="jobsfeed-new-position-card">
          <img src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F4095%2Fyordqgyngzdohelg__400_400.jpg&w=400&q=75"/>
          <div>[커머스그룹] 바이럴 마케터</div>
          <div>패스트뷰</div>
          <div>서울 · 한국 </div>
          <div>채용보상금 1,000,000원</div>
          <button className="jobsfeed-bookmark-btn">
            <FontAwesomeIcon icon={farBookmark}/>
          </button>
        </div>
      </div>
    </div>
  )
}




export {JobsfeedPage};
