import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark, faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import { MainFooter, MainHeader } from '../Common/Common';
import { SalaryContentCards } from '../SalaryPage/SalaryPage';
import './JobDetailPage.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';


const JobDetailPage = ()=>{
  return(
    <>
      <MainHeader/>
      <JobDetailMain/>
      <div className='main-container' style={{marginTop:"60px"}}>
        <SalaryContentCards/>
      </div>
      <MainFooter/>
    </>
  )
}

const JobDetailMain = ()=>{
  return(
    <div className='job-detail-main-back'>
      <div className='main-container flex-row'>
        <JobDetailMainLeftBox/>
        <JobDetailMainRightBox/>
      </div>
    </div>
  )
}

const JobDetailMainLeftBox = ()=>{

  let {id} = useParams();

  // 채용 상세 데이터 저장할 state
  const [ApplyDetailData, setApplyDetailData] = useState('');

  // 채용 상세 데이터 불러오기
  useEffect( ()=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${id}`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url,{headers:config})
      .then((res)=>{
        // console.log(res.data.result);
        setApplyDetailData(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[])


  return(
    <div className='job-detail-main-left-box-back'>
      {
        ApplyDetailData !== '' && ApplyDetailData !== undefined?
        <>
          <div className='job-detail-left-carousel-back'>
            <div className='job-detail-left-carousel-contents'>
              <img src={ApplyDetailData[0].companyImage1}/>
            </div>
          </div>
          <div className='job-detail-left-title'>{ApplyDetailData[0].positionName}</div>
          <div className='flex-row job-detail-company-info'>
            <div>{ApplyDetailData[0].companyName}</div>
            <div>{ApplyDetailData[0].companyResponseRateWord}</div>
            <div>{ApplyDetailData[0].region} · {ApplyDetailData[0].country}</div>
          </div>
          <div className='flex-row job-detail-left-hashtags'>
            <span>#인원급성장</span>
            <span>#50명이하</span>
            <span>#설립10년이상</span>
            <span>#인원급성장</span>
            <span>#50명이하</span>
            <span>#설립10년이상</span>
            <span>#인원급성장</span>
            <span>#50명이하</span>
            <span>#설립10년이상</span>
          </div>
          <div className='job-detail-left-explanation' style={{marginTop:"20px"}}>{ApplyDetailData[0].positionExplanation}</div>
          <div className='job-detail-left-explanation-title'>주요업무</div>
          <div className='job-detail-left-explanation'>{ApplyDetailData[0].mainBusiness}</div>
          <div className='job-detail-left-explanation-title'>자격요건</div>
          <div className='job-detail-left-explanation'>{ApplyDetailData[0].qualificationRequirement}</div>
          <div className='job-detail-left-explanation-title'>우대사항</div>
          <div className='job-detail-left-explanation'>{ApplyDetailData[0].preferentialTreatment}</div>
          <div className='job-detail-left-explanation-title'>혜택 및 복지</div>
          <div className='job-detail-left-explanation'>{ApplyDetailData[0].benefitAndWelfare}</div>
          <div className='job-detail-left-explanation-title'>기술스택 ・ 툴</div>
          <div className='job-detail-left-explanation job-detail-left-tech-stack'>
            <span>html</span>
            <span>css</span>
            <span>javascript</span>
          </div>
          
          <div className='job-detail-left-divider'></div>

          <div className='job-detail-left-day-area flex-row'>
            <div>마감일</div>
            <div>{ApplyDetailData[0].deadLine}</div>
          </div>
          <div className='job-detail-left-day-area flex-row'> 
            <div>근무지역</div>
            <div>{ApplyDetailData[0].serviceArea}</div>
          </div>
        </>
        :null
      }


    </div>
  )
}

const JobDetailMainRightBox = ()=>{

  let {id} = useParams();

  library.add(farBookmark);
  library.add(farHeart);

  // 북마크 선택시 변할 버튼 스타일을 위한 state
  const [BookMarkBtnIndex, setBookMarkBtnIndex] = useState([]);

  // 내 아이디에서 북마크 불러오기
  useEffect( ()=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        // console.log(res.data);
        // 버튼 스타일을 위한 인덱스 저장
        let bookmarkBtnIndexArr = [];
        res.data.result.map((data)=>{ bookmarkBtnIndexArr.push(data.recruitmentPostId); }) 
        setBookMarkBtnIndex(bookmarkBtnIndexArr);  // 현재들어있는 bookmark의 id 를 저장
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[])

  // 북마크 클릭시 : 내 북마크 리스트 조회하기
  const ShowMyBookMarkList = (PostId)=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        // console.log(res.data);
        
        // 현재 누른 포스트의 id가 내 bookmark에 저장된 포스트의 id에 포함되는지 찾기
        let findBookList = res.data.result.find( function(data){ return data.recruitmentPostId === PostId } );
        // 포함되어있다면 삭제 , 포함되어 있지 않다면 추가 
        if (findBookList !== undefined){
          DeleteMyBookMarkList(PostId);
          // console.log("있는데요");
        } else if(findBookList === undefined) {
          AddMyBookMarkList(PostId);
          // console.log("없는데요");
        }

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  };
  // 북마크 클릭시 : 내 북마크 리스트에 추가하기
  const AddMyBookMarkList = (PostId)=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${PostId}/plus-bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.post(url,{},{headers:config})
      .then((res)=>{
        // 내 리스트에 추가했을때 빨간색 북마크로 표시해주기
        let plusBookMarkBtnIndex = [...BookMarkBtnIndex];
        plusBookMarkBtnIndex.push(PostId);
        setBookMarkBtnIndex( plusBookMarkBtnIndex );  

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  };

  // 북마크 클릭시 : 내 북마크 리스트에서 삭제하기
  const DeleteMyBookMarkList = (PostId)=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${PostId}/minus-bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.patch(url,{},{headers:config})
      .then((res)=>{
        let minusBookMarkBtnIndex = [...BookMarkBtnIndex];
        // minusBookMarkBtnIndex.push(PostId);
        let filterdminusBookMarkBtnIndex = minusBookMarkBtnIndex.filter((element)=>element !== PostId );

        setBookMarkBtnIndex( filterdminusBookMarkBtnIndex );  

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  }


  return(
    <div className='job-detail-main-right-box-back'>
      <div className='flex-row job-detail-right-title'>
        <div>채용보상금</div>
        <button><FontAwesomeIcon icon={faShareAlt} className='job-detail-right-share-icon'/></button>
      </div>
      <div className='flex-row job-detail-right-money-box'>
        <div>
          <div className='job-detail-right-money-box-text'>추천인</div>
          <div className='job-detail-right-money-box-money'>500,000원</div>
        </div>
        <div>
          <div className='job-detail-right-money-box-text'>지원자</div>
          <div className='job-detail-right-money-box-money'>500,000원</div>
        </div>
      </div>
      {
        BookMarkBtnIndex.includes(parseInt(id))?
        <button className='job-detail-right-bookmark-btn flex-row'
          onClick={()=>{                         
            // 내 북마크 리스트 조회 -> 삭제 or 추가
            ShowMyBookMarkList(parseInt(id)); }}>
          <FontAwesomeIcon icon={faBookmark} className='icon-style'/> 북마크완료
        </button>
        :
        <button className='job-detail-right-bookmark-btn flex-row'
          onClick={()=>{                         
            // 내 북마크 리스트 조회 -> 삭제 or 추가
            ShowMyBookMarkList(parseInt(id)); }}>
          <FontAwesomeIcon icon={farBookmark} className='icon-style'/> 북마크하기
        </button>
      }
      {/* <button className='job-detail-right-bookmark-btn flex-row'>
        <FontAwesomeIcon icon={farBookmark} className='icon-style'/> 북마크하기
      </button> */}
      <button className='job-detail-right-apply-btn'>지원하기</button>
      <button className='job-detail-right-like-btn'><FontAwesomeIcon icon={farHeart} className='icon-style'/> 0</button>
    </div>
  )
}



export {JobDetailPage};



