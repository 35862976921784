import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeHeaderStyle } from '../../store/store';
import { MainHeader } from '../Common/Common';
import './EventPage.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faSliders  } from '@fortawesome/free-solid-svg-icons';
import { faBell as farBell } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import styled from 'styled-components';
import { EventPageContentsCardData } from './EventPageData';
import axios from 'axios';

const EventPage = ()=>{


  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(2));
  },[])


  return(
    <>
      <MainHeader/>
      <Link to='/events'><div className='event-top-banner-back'></div></Link>
      <EventPageMain/>
    </>
  )
}





const EventPageCardLabel = styled.div`
  color: ${props=>props.LabelColor};
  border-color : ${props=>props.LabelColor};
  border: 1px solid;
  width:fit-content;
  margin-top: 12px;
  margin-bottom: 4px;
  height: 24px;
  line-height:24px;
  font-size: 12px;
  font-weight:600;
  padding: 0 6px;
`;

const EventPageMain = ()=>{
  library.add(farBell);

  const eventCarouselContentBtn = ['전체','개발','HR','취업/이직','회사생활',
                                    '디자인','노무','경영/전략','마케팅','커리어고민',
                                    '브랜딩','리더십','조직문화','프리랜서','서비스기획',
                                    'UX/UI','콘텐츠 제작','IT/기술','CS/CX','영업/세일즈',
                                    '라이프스타일','데이터','교육','금융/핀테크','게임','인간관계']; // 총 21개

  // 이벤트 카드 데이터 담을 state
  const [eventGetData, setEventGetData] = useState('');

  // 데이터 불러오기
  useEffect( ()=>{
    const url = "https://prod.risingserver13forever.shop/app/events";
    try{
      axios.get(url)
      .then((res)=>{
        // console.log(res.data.result);
        setEventGetData(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[])


  // 캐러샐 보이는지 안보이는지
  const [EventCarouselBtn, setEventcarouselBtn] = useState(["none","flex"]);
  // 어떤 버튼눌렀을떄 스타일 변경
  const [EventCarouselBtnStyle, setEventcarouselBtnStyle] = useState(['event-click','event-not-click','event-not-click','event-not-click','event-not-click'
  ,'event-not-click','event-not-click','event-not-click','event-not-click','event-not-click'
  ,'event-not-click','event-not-click','event-not-click','event-not-click','event-not-click'
  ,'event-not-click','event-not-click','event-not-click','event-not-click','event-not-click','event-not-click']);
  // 현재 몇번 버튼을 눌렀는지
  const [EventCarouselCurrentIndex, setEventCarouselCurrentIndex] = useState(0);
  // 이동할 px
  const [EventCarouselpx, setEventCarouselpx] = useState(0); 

  // 각각의 카테고리 버튼 눌렀을때 이동
  useEffect(()=>{
    if(EventCarouselCurrentIndex<=5){
      setEventCarouselpx(0);
      setEventcarouselBtn(["none","flex"]);
    } if(EventCarouselCurrentIndex>5){
      setEventCarouselpx(-400);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselCurrentIndex>10){
      setEventCarouselpx(-800);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselCurrentIndex>14){
      setEventCarouselpx(-1300);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselCurrentIndex>18){
      setEventCarouselpx(-1700);
      setEventcarouselBtn(["flex","none"]);
    }
  },[EventCarouselCurrentIndex]);


  // 버튼으로 이동하기
  const CarouselBtnFunc = (type)=>{
    if(EventCarouselpx==0 && type==1){
      setEventCarouselpx(-400);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselpx==-400 && type==1){
      setEventCarouselpx(-800);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselpx==-800 && type==1){
      setEventCarouselpx(-1300);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselpx==-1300 && type==1){
      setEventCarouselpx(-1700);
      setEventcarouselBtn(["flex","none"]);
    }

    if(EventCarouselpx==-1700 && type==-1){
      setEventCarouselpx(-1300);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselpx==-1300 && type==-1){
      setEventCarouselpx(-800);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselpx==-800 && type==-1){
      setEventCarouselpx(-400);
      setEventcarouselBtn(["flex","flex"]);
    } if(EventCarouselpx==-400 && type==-1){
      setEventCarouselpx(0);
      setEventcarouselBtn(["none","flex"]);
    }
  }



  return(
    <div className='event-page-main-back main-container'>
      <div className='event-page-main-title'>다양한 커리어 관련 이벤트를 만나보세요!</div>

      <div className='event-page-main-sticky-banner flex-column'>
        <div className="event-carousel-back flex-row">
          <div className="event-carousel-box">
            <div className="event-carousel-contents" style={{transform:`translateX(${EventCarouselpx}px)`}}>
              {eventCarouselContentBtn.map((data,index)=>{
                return(
                  <button key={index} className={`event-carousel-content-btn ${EventCarouselBtnStyle[index]}`}
                    onClick={()=>{
                      let currentBtn = ['event-not-click','event-not-click','event-not-click','event-not-click','event-not-click'
                      ,'event-not-click','event-not-click','event-not-click','event-not-click','event-not-click'
                      ,'event-not-click','event-not-click','event-not-click','event-not-click','event-not-click'
                      ,'event-not-click','event-not-click','event-not-click','event-not-click','event-not-click', 'event-not-click'];
                      currentBtn[index] = 'event-click';
                      setEventcarouselBtnStyle(currentBtn);
                      setEventCarouselCurrentIndex(index);

                    }}>
                    {data}
                  </button>  
                )
              })}
            </div>
            <div className="event-carousel-move-btn-box move-btn-left-box" >
            {/* style={{display:`${eventCarouselBtn[0]}`}}  */}
              <button className="event-carousel-move-btn" style={{display:`${EventCarouselBtn[0]}`}} onClick={()=>{ CarouselBtnFunc(-1); }}>
                <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
            </div>
            <div className="event-carousel-move-btn-box move-btn-right-box">
            {/* style={{display:`${eventCarouselBtn[1]}`}} */}
              <button className="event-carousel-move-btn" style={{display:`${EventCarouselBtn[1]}`}} onClick={()=>{ CarouselBtnFunc(1); }}>
                <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            </div>
          </div>

          <div>
            <button type="button" className="event-carousel-open-btn"><svg width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M12 10a2 2 0 1 1-.001 4.001A2 2 0 0 1 12 10zm7 0a2 2 0 1 1-.001 4.001A2 2 0 0 1 19 10zM5 10a2 2 0 1 1-.001 4.001A2 2 0 0 1 5 10z"></path></svg></button>
          </div>
        </div>
        <div className='event-sticky-divider'></div>
        <div className='flex-row event-sticky-filter-alarm'>
          <button className='event-sticky-filter-btn'>
            <FontAwesomeIcon icon={faSliders} className='event-sticky-filter-btn-icon'/>
            <span>필터 및 정렬</span>
            <div className='event-sticky-filter-btn-num-box'>1</div>
          </button>
          <button className='event-sticky-alarm-btn'>
            <FontAwesomeIcon icon={farBell} className='icon-style'/>
            <span>키워드 알림 신청</span>
          </button>
        </div>
        <div className='event-sticky-divider-bottom'></div>
      </div>

      <div className='event-page-main-contents-back'>

        {
          eventGetData != ''?
          eventGetData.map((data, index)=>{
            return(
              <Link to='/events' key={data.eventId}>
                <div className='event-page-main-content-card-back'>
                  <img src={data.representativeContentsImg}/>
                  <EventPageCardLabel LabelColor={data.eventType}>{data.eventType}</EventPageCardLabel>
                  <div className='event-page-main-content-card-title'>{data.eventTitle}</div>
                  <div className='event-page-main-content-card-date'>{data.date}</div>
                  <div className='event-page-main-content-card-tags'>
                    {
                      data.eventTotalTags.map((data2, index)=>{
                        return(
                          <span key={index}>#{data2}</span>
                        )
                      })
                    }
                  </div>
                </div>
              </Link>
            )
          })
          : null
        }
        {/* {
          EventPageContentsCardData.map((data)=>{
            return(
              <div className='event-page-main-content-card-back' key={data.index}>
                <img src={data.img}/>
                <EventPageCardLabel LabelColor={data.label[0]}>{data.label[1]}</EventPageCardLabel>
                <div className='event-page-main-content-card-title'>{data.title}</div>
                <div className='event-page-main-content-card-date'>{data.date}</div>
                <div className='event-page-main-content-card-tags'>
                  {
                    data.tags.map((data2, index)=>{
                      return(
                        <span key={index}>{data2}</span>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        } */}


      </div>
    </div>
  )
}





export {EventPage};


