import { Link, useNavigate } from 'react-router-dom';
import './Common.css';
import { faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell as farBell } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ShowSearchModal } from '../../store/store';


const MainHeader = ()=>{
  library.add(farBell)

  let navigate = useNavigate();
  let mainHeaderStyle = useSelector((state)=>{ return state.mainHeaderStyle });

  const [loginState, setLoginState] = useState("");
  const [loginUserInfo, setLoginUserInfo] = useState("");
  const [loginResumeState, setLoginResumeState] = useState("/resume");

  useEffect( ()=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        console.log(res.data);
        if(res.data.code === 1000){
          setLoginState("login");
          setLoginResumeState("/resume/list");
          setLoginUserInfo(res.data.result);
        } else{
          setLoginState("logout");
          setLoginResumeState("/resume");
          setLoginUserInfo("logout");
        }
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  },[])

  // 검색모달창 띄워주기
  let SearchModal = useSelector((state)=>{ return state.SearchModal });
  // console.log(SearchModal)
  let dispatch = useDispatch();


  // 마이페이지 메뉴바 띄우기
  const [ShowMyPageMenuBar, setShowMyPageMenuBar] = useState('none');

  // 로그아웃 클릭시
  const LogoutFunc = async()=>{
    await localStorage.removeItem('userid');
    await localStorage.removeItem('jwt');

    window.location.replace("/");
  } 



  return(
    <>
      <div className='mainheader-back'>
        <SearchModalPage/>
        <div className='mainheader-content main-container'>
          <div className='mainheader-left flex-row'>
            <button className='mainheader-btn'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Ficon-menu.png&w=17&q=75' />
            </button>
            <Link to='/'>
              <img className='header_logo' src='/assets/img/wanted_logo.PNG' />
            </Link>
          </div>
          <div className='mainheader-center flex-row'>
            <Link to='/jobsfeed' style={{borderBottom:`2px solid ${mainHeaderStyle[1]}`}}>채용</Link>
            <Link to='/events' style={{borderBottom:`2px solid ${mainHeaderStyle[2]}`}}>이벤트</Link>
            <Link to='/salary' style={{borderBottom:`2px solid ${mainHeaderStyle[3]}`}}>직군별 연봉</Link>
            <Link to={loginResumeState} style={{borderBottom:`2px solid ${mainHeaderStyle[4]}`}}>이력서</Link>
            <Link to='/community' style={{borderBottom:`2px solid ${mainHeaderStyle[5]}`}}>
              커뮤니티
              <em className='mainheader-label'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11"><g fill="none" fillRule="evenodd" fontFamily="AppleSDGothicNeo-SemiBold, Apple SD Gothic Neo" fontSize="9" fontWeight="500"><g fill="#36F"><g><g><g><g><g><g><text transform="translate(-931.000000, -13.000000) translate(224.000000, 7.000000) translate(210.000000, 6.000000) translate(350.000000, 0.000000) translate(147.000000, 0.000000)"><tspan x="0" y="8">New</tspan></text></g></g></g></g></g></g></g></g></svg></em>
            </Link>
            <a href='https://www.wanted.co.kr/gigs' style={{borderBottom:`2px solid ${mainHeaderStyle[6]}`}}>프리랜서</a>
            <Link to='/aiscore' style={{borderBottom:`2px solid ${mainHeaderStyle[7]}`}}>
              AI 합격예측
              <span className='mainheader-label'><svg xmlns="https://www.w3.org/2000/svg" width="18" height="11" viewBox="0 0 18 11"><g fill="none" fillRule="evenodd" fontFamily="AppleSDGothicNeo-SemiBold, Apple SD Gothic Neo" fontSize="9" fontWeight="500"><g fill="#36F"><g><g><g><g><g><g><text transform="translate(-931.000000, -13.000000) translate(224.000000, 7.000000) translate(210.000000, 6.000000) translate(350.000000, 0.000000) translate(147.000000, 0.000000)"><tspan x="0" y="8">Beta</tspan></text></g></g></g></g></g></g></g></g></svg></span>
            </Link>
          </div>
          <div className='mainheader-right flex-row'>
            <ul className='flex-row'>
              <li className='search-li'>
                <button onClick={()=>{
                  if (SearchModal==="none"){
                    dispatch( ShowSearchModal('block') )
                  }
                }}>
                  <FontAwesomeIcon className='serach-icon' icon={faSearch} />
                </button>
              </li>
              <li>
                {
                  loginState==='login'?
                  <div className='flex-row' style={{position:"relative"}}>
                    <FontAwesomeIcon icon={farBell} className='mainheader-user-alarm-bell'/>
                    <Link to='' onClick={()=>{
                      if(ShowMyPageMenuBar==='none'){
                        setShowMyPageMenuBar('block');
                      } else{
                        setShowMyPageMenuBar('none');
                      }
                      }}>
                      <img src={loginUserInfo.profileImgUrl} className='mainheader-user-profile-img'/>
                    </Link>
                    <div className='login-click-triangle' style={{display:ShowMyPageMenuBar}}></div>
                    <div className='login-click-box' style={{display:ShowMyPageMenuBar}}>
                      <div><Link to='/'>MY 원티드</Link></div>
                      <div><Link to='/'>프로필</Link></div>
                      <div className='login-click-divider'></div>
                      <div><Link to='/'>지원 현황</Link></div>
                      <div><Link to='/'>제안받기 현황</Link></div>
                      <div><Link to='/'>좋아요</Link></div>
                      <div><Link to='/bookmarks'>북마크</Link></div>
                      <div className='login-click-divider'></div>
                      <div><Link to='/'>추천</Link></div>
                      <div><Link to='/'>포인트</Link></div>
                      <div className='login-click-divider'></div>
                      <div><Link to='' onClick={()=>{ LogoutFunc(); }} >로그아웃</Link></div>
                    </div>
                  </div>
                  : <button className='login-btn' onClick={()=>{navigate('/login')}}>회원가입/로그인</button>
                }
                
              </li>
              <li className='service-li flex-row'>
                <div className='header-divider'></div>
                <button className='service-btn'>기업 서비스</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

const MainFooter = ()=>{
  return(
    <div className='main-footer-back'>
      <div className='main-container'>
        <div className='main-footer-top-contents flex-row'>
          <div className='main-footer-navbar flex-row'>
            <Link to='/' className='main-footer-wanted-logo'>
              <svg width="31.4%" height="100%" viewBox="0 0 79 32"><path fill="#24e0a6" d="M27.755 5.155A15.953 15.953 0 0015.992 0C7.152 0-.012 7.164-.012 16.004s7.164 16.004 16.004 16.004c4.653 0 8.84-1.985 11.763-5.155A15.953 15.953 0 0031.996 16c0-4.183-1.606-7.992-4.241-10.845z"></path><path fill="#438bff" d="M51.286 5.155A15.953 15.953 0 0039.523 0c-8.84 0-16.004 7.164-16.004 16.004s7.164 16.004 16.004 16.004c4.653 0 8.84-1.985 11.763-5.155A15.953 15.953 0 0055.527 16c0-4.179-1.61-7.992-4.241-10.845z"></path><path fill="#2c5bf2" d="M74.812 5.155A15.953 15.953 0 0063.049 0c-8.84 0-16.004 7.164-16.004 16.004s7.164 16.004 16.004 16.004c4.653 0 8.84-1.985 11.763-5.155A15.953 15.953 0 0079.053 16c0-4.183-1.61-7.992-4.241-10.845z"></path><path fill="#24e0bc" d="M27.755 5.155A15.953 15.953 0 0015.992 0C7.152 0-.012 7.164-.012 16.004s7.164 16.004 16.004 16.004c4.653 0 8.84-1.985 11.763-5.155A15.953 15.953 0 0123.514 16c0-4.183 1.61-7.992 4.241-10.845z"></path><path fill="#438bff" d="M51.282 5.155C48.359 1.985 44.171 0 39.519 0s-8.84 1.985-11.763 5.155a15.953 15.953 0 014.241 10.853c0 4.187-1.61 8-4.241 10.853 2.923 3.17 7.111 5.155 11.763 5.155s8.84-1.985 11.763-5.155c-2.631-2.853-4.241-6.662-4.241-10.853s1.61-8 4.241-10.853z"></path><path fill="#3a68f9" d="M27.755 5.155a15.953 15.953 0 00-4.241 10.853c0 4.187 1.61 8 4.241 10.853 2.631-2.853 4.241-6.662 4.241-10.853s-1.606-8-4.241-10.853z"></path><path fill="#2c5bf2" d="M63.049 0c-4.653 0-8.84 1.985-11.763 5.155a15.953 15.953 0 014.241 10.853c0 4.187-1.61 8-4.241 10.853a15.953 15.953 0 0011.763 5.155c8.84 0 16.004-7.164 16.004-16.004C79.053 7.164 71.885 0 63.049 0z"></path><path fill="#0049db" d="M51.282 5.155a15.953 15.953 0 00-4.241 10.853c0 4.187 1.61 8 4.241 10.853a15.953 15.953 0 004.241-10.853c.004-4.191-1.606-8-4.241-10.853z"></path></svg>
              <svg width="62.96%" height="100%" viewBox="0 0 140 32"><path fill="currentColor" d="M89.8 2.2l-5.6 2.4v4.8h-3.8v5.2h3.8v10.2c0 4.2 2.6 7 6.6 7 1.6 0 2.6-.4 3.2-.6V26c-.2 0-1 .2-1.8.2-1.6 0-2.4-.6-2.4-2.6v-8.8H94V9.6h-4.2V2.2zM28.6 9.6l-4 14-4.6-14h-5.6l-4.6 14L6 9.6H0l6.8 21.8h6l4.4-14.6 4.6 14.6h6l6.8-21.8zM134.4 2.2v8.6c-1.4-1-3-1.6-4.8-1.8h-.4-1.6c-5 .4-8.2 4.2-9.2 9-.2.8-.2 1.4-.2 2.2V22c.6 5.6 4.4 10 10.2 10 2.4 0 4.4-.6 6-1.8v1.4h5.4V0l-5.4 2.2zm-5.2 24.4c-3 0-5.6-2.4-5.6-6.2 0-4 2.6-6.2 5.6-6.2s5.2 2.2 5.2 6c0 4.2-2.2 6.4-5.2 6.4zM116.2 18c-.8-5.2-4.6-9-10-9s-9.2 3.8-10 9c-.2.8-.2 1.6-.2 2.6v1.6c.6 5.6 4.4 10 10.2 10 4.6 0 8-2.8 9.4-6.8l-5-1.2c-.8 1.8-2.4 3.2-4.4 3.2-2.8 0-4.6-2.2-5-5.2h15.2v-1.6c0-1 0-1.8-.2-2.6zm-14.8 0c.8-2.2 2.4-3.6 4.8-3.6s4 1.6 4.8 3.6h-9.6zM50.6 11c-1.4-1-3.2-1.8-5.2-1.8H44.8h-.6c-5.2.4-8.6 4-9.4 9-.2.8-.2 1.4-.2 2.2v1.8c.6 5.6 4.4 10 10.2 10 2.4 0 4.4-.6 6-1.8v1.4h5.6V9.6h-5.6V11zm-5.2 15.6c-3 0-5.6-2.4-5.6-6.2 0-4 2.6-6.2 5.6-6.2s5.2 2.2 5.2 6c0 4.2-2.2 6.4-5.2 6.4zM71.2 9c-2.2 0-4.6 1-6 3.2V9.6h-5.6v21.8h5.6V18.8c0-2.6 1.4-4.6 4-4.6 2.8 0 3.8 2 3.8 4.4v12.8h5.6V17.6c.2-4.8-2.2-8.6-7.4-8.6z"></path></svg>
            </Link>
            <Link to='/' className='main-footer-navbar-content'>기업소개</Link>
            <Link to='/' className='main-footer-navbar-content'>이용약관</Link>
            <Link to='/' className='main-footer-navbar-content'>고객센터</Link>
            <Link to='/' className='main-footer-navbar-content personal-info'>개인정보 처리방침</Link>
          </div>
          <div className='main-footer-share-icons'>
            <Link to='/' className='main-footer-share-icon'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fuserweb%2Fsocial_instagram.png&w=20&q=100'/>
            </Link>
            <Link to='/' className='main-footer-share-icon'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fuserweb%2Fsocial_youtube.png&w=25&q=100'/>
            </Link>
            <Link to='/' className='main-footer-share-icon'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fuserweb%2Fsocial_facebook.png&w=20&q=100'/>
            </Link>
            <Link to='/' className='main-footer-share-icon'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fuserweb%2Fsocial_blog.png&w=20&q=100'/>
            </Link>
            <Link to='/' className='main-footer-share-icon'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fuserweb%2Fsocial_apple.png&w=17&q=100'/>
            </Link>
            <Link to='/' className='main-footer-share-icon'>
              <img src='https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fuserweb%2Fsocial_google.png&w=17&q=100'/>
            </Link>
          </div>
        </div>
        <div className='main-footer-bottom-contents flex-row'>
          <p className="main-footer-bottom-text">(주)원티드랩 (대표이사:이복기) | 서울특별시 송파구 올림픽로 300 롯데월드타워 35층 | 통신판매번호 : 2020-서울송파-3147
            <br/>유료직업소개사업등록번호 : (국내) 제2020-3230259-14-5-00018호 | 사업자등록번호 : 299-86-00021 | 02-539-7118
            <br/>© Wantedlab, Inc.
          </p>
          <div className='main-footer-languege-select-box'>
            <img className='main-footer-languege-select-img' src='https://static.wanted.co.kr/images/wantedoneid/ico_KR.svg' />
            <select className='main-footer-languege-select'>
              <option>한국(한국어)</option>
              <option>English</option>
              <option></option>
            </select>
          </div>
        </div>
      </div>
    </div>
  )
}

const SearchModalPage =()=>{

  // 검색모달창 띄워주기
  let SearchModal = useSelector((state)=>{ return state.SearchModal });
  // console.log(SearchModal)
  let dispatch = useDispatch();

  return(
    <div className='search-modal-back' style={{display:SearchModal}}
      onClick={(e)=>{
        if (SearchModal==="block"){
          dispatch( ShowSearchModal('none') )
        }
      }}>
      <div className='search-modal-contents-box' onClick={(e)=>{e.stopPropagation();}}>
        <div className='main-container'>
          <form className='search-modal-form'>
            <input placeholder='#태그, 회사, 포지션 검색'/>
            <button> <FontAwesomeIcon icon={faSearch} style={{width:"18px",height:"18px"}}/> </button>
          </form>
          <div className='flex-row search-modal-text-title'>
            <div>추천태그로 검색해보세요</div>
            <div>기업태그 홈 이동하기 <FontAwesomeIcon icon={faChevronRight}/></div>
          </div>
          <div className='flex-row search-modal-btns-box'>
            <button>#육아휴직</button>
            <button>#Macbook</button>
            <button>#연봉상위2-5%</button>
            <button>#스터디지원</button>
            <button>#퇴사율5%이하</button>
          </div>
        </div>
      </div>
    </div>
  )
}




export {MainHeader, MainFooter};





