import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, faArrowLeftRotate, faPencil } from "@fortawesome/free-solid-svg-icons";
import { faThumbsUp as farThumbsUp, faComment as farComment } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeHeaderStyle } from "../../store/store";
import { MainHeader } from "../Common/Common";

import './CommunityPage.css';
import { CommunityMainContentsCardData } from "./CommunityPageData";

const CommunityPage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(5));
  },[])


  return(
    <>
      <MainHeader/>
      <CommunityMain/>
    </>
  )
}


const CommunityMain = ()=>{
  return(
    <div className="community-main-back">
      <div className="community-main-contents-hidden-box"></div>
      <div className="community-main-contents-box main-container flex-row">
        <div className="community-main-left-box-back flex-column">
          <div className="community-main-left-box-contents-box">
            <div className="community-main-left-box-my-community">
              <div>MY 커뮤니티</div>
              <div className="flex-row community-main-left-box-my-info">
                <img src="https://static.wanted.co.kr/images/profile_default.png"/>
                <div>로그인 해주세요</div>
                <FontAwesomeIcon icon={faChevronRight} style={{marginTop:"12px"}}/>
              </div>
            </div>
            <div className="community-main-left-box-banner">
              <div>나를 잘 표현하고 싶다면? <br/> 커뮤니티 프로필 완성하기</div>
            </div>
            <div className="community-main-left-box-update-back">
              <div>업데이트된 추천 프로필</div>
              <div>관심있는 프로필을 찾아보세요</div>
              <div className="flex-row community-main-left-box-my-info profile-info">
                <img src="https://static.wanted.co.kr/community/avatar/c806b9da3e936433052f79b6e39803673cf1b7eff6100b8e031de3334b7d9772"/>
                <div>사람</div>
                <FontAwesomeIcon icon={faChevronRight} style={{marginTop:"12px"}}/>
              </div>
              <div className="flex-row community-main-left-box-my-info profile-info">
                <img src="https://static.wanted.co.kr/oneid-user/AF5GwLjsc3GSYXRmA3A2tP/5296e43c18c5f23be16d408273fcc0a915d4a50258639592b6c89770865504e6"/>
                <div>SoftlyAI</div>
                <FontAwesomeIcon icon={faChevronRight} style={{marginTop:"12px"}}/>
              </div>
              <div className="flex-row community-main-left-box-my-info profile-info">
                <img src="https://static.wanted.co.kr/open_profile/avatar/faff109b7da5deba1527607067db6d0154f54f3e8c1d6ea96ff746943872281f"/>
                <div>근로복지넷</div>
                <FontAwesomeIcon icon={faChevronRight} style={{marginTop:"12px"}}/>
              </div>
              <div className="flex-row community-main-left-box-my-info profile-info">
                <img src="https://static.wanted.co.kr/open_profile/avatar/148632dd6df4c4f653db758895a46563bd51f15f4de1103d6341b1a1d2b41fc1"/>
                <div>칸타쿤트론</div>
                <FontAwesomeIcon icon={faChevronRight} style={{marginTop:"12px"}}/>
              </div>
              <div className="flex-row community-main-left-box-my-info profile-info">
                <img src="https://static.wanted.co.kr/open_profile/avatar/f0b634111ee9f45b96a7b25ab5d9a4be1a8ccfed14cdc05250309b1008467814"/>
                <div>란스</div>
                <FontAwesomeIcon icon={faChevronRight} style={{marginTop:"12px"}}/>
              </div>
              <button className="community-main-left-box-profile-btn">새로운 프로필보기 <FontAwesomeIcon icon={faArrowLeftRotate}/> </button>
            </div>
          </div>
        </div>

        <div className="community-main-right-box-back flex-column">
          <CommunityCarousel/>

          <div className="community-main-right-box-contents-back">
            <div className="community-right-box-this-week">
              <div className="community-right-box-this-week-title-back flex-row">
                <div>이번 주 많이 본 글  🏆</div>
                <div className="flex-row">
                  <div className="community-right-box-nav-num"> <span>1</span> <span>/</span> <span style={{marginRight:"15px"}}>4</span> </div>
                  <button className="community-right-box-nav-btn"><FontAwesomeIcon icon={faChevronLeft}/></button>
                  <button className="community-right-box-nav-btn"><FontAwesomeIcon icon={faChevronRight}/></button>
                </div>
              </div>
              <div className="community-right-box-this-week-contents flex-row">
                <div className="community-right-box-this-week-card">
                  <div>조회수 TOP</div>
                  <div>MZ 세대가 본 MZ 오피스 - 1. 시끄러운 입사</div>
                  <div className="community-right-box-this-week-card-profile flex-row">
                    <img src="https://static.wanted.co.kr/community/avatar/db659793d080814f2a98d5f405bd6398f54da0f6f4af02b505e797f3f344a340"/>
                    <div>신연권</div>
                  </div>
                </div>
                <div className="community-right-box-this-week-card">
                  <div>조회수 TOP</div>
                  <div>"개인의 성장과 회사의 성장은 비례하지 않는다"</div>
                  <div className="community-right-box-this-week-card-profile flex-row">
                    <img src="https://static.wanted.co.kr/images/avatars/2220002/eb31436c.jpg"/>
                    <div>스탠리</div>
                  </div>
                </div>
                <div className="community-right-box-this-week-card">
                  <div>조회수 TOP</div>
                  <div>현재 직무로 옮긴지 8개월째..인사이동시기 전 퇴사하게 될것같아요 (잘 퇴사 하는 법?)</div>
                  <div className="community-right-box-this-week-card-profile flex-row">
                    <img src="https://static.wanted.co.kr/open_profile/avatar/252264bbf0bf702f159bdea4f40f8dce76a61db4efecabdb5ed1ae663ce4efd1"/>
                    <div>퇴사예정자</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="community-right-box-write-btn-back">
              <button className="flex-row community-right-box-write-btn">
                <span>커리어와 라이프스타일에 대해 자유롭게 이야기 해주세요</span>
                <FontAwesomeIcon icon={faPencil}/>
              </button>
            </div>
            <CommunityMainContentsCard/>
          </div>
        </div>


      </div>
    </div>
  )
}


const CommunityCarousel = ()=>{

  const communityCarouselContentBtn = ['추천','전체','커리어고민','취업/이직','회사생활','인간관계','IT/기술','라이프스타일','리더십','조직문화','MD','디자인','HR',
  '데이터','브랜딩','노무','콘텐츠 제작','서비스기획','한입 콘텐츠','경영/전략','마케팅','UX/UI', '개발']

  return(
    <div className="community-carousel-back flex-row">
      <div className="community-carousel-box">
        <div className="community-carousel-contents">
          {communityCarouselContentBtn.map((data,index)=>{
            return(
              <button key={index} className="community-carousel-content-btn">{data}</button>  
            )
          })}
        </div>
        <div className="community-carousel-move-btn-box move-btn-left-box" >
        {/* style={{display:`${communityCarouselBtn[0]}`}}  */}
          <button className="community-carousel-move-btn">
            <FontAwesomeIcon icon={faChevronLeft}/>
          </button>
        </div>
        <div className="community-carousel-move-btn-box move-btn-right-box">
        {/* style={{display:`${communityCarouselBtn[1]}`}} */}
          <button className="community-carousel-move-btn">
            <FontAwesomeIcon icon={faChevronRight}/>
          </button>
        </div>
      </div>

      <div>
        <button type="button" className="community-carousel-open-btn"><svg width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M12 10a2 2 0 1 1-.001 4.001A2 2 0 0 1 12 10zm7 0a2 2 0 1 1-.001 4.001A2 2 0 0 1 19 10zM5 10a2 2 0 1 1-.001 4.001A2 2 0 0 1 5 10z"></path></svg></button>
      </div>
    </div>
  )
}

const CommunityMainContentsCard = ()=>{

  library.add(farThumbsUp);
  library.add(farComment);

  return(
    <div className="community-main-contents-cards-back">
      <div className="community-main-contents-header">
        <div>추천 커뮤니티글   💘</div>
        <div>#커리어고민 #취업/이직 #회사생활 #IT/기술 #라이프스타일 #리더십 #조직문화 <FontAwesomeIcon icon={faChevronRight}/></div>
      </div>

      {
        CommunityMainContentsCardData.map((data)=>{
          return(
            <div className="community-main-contents-card-back" key={data.index}>
              <div className="community-main-contents-card-profile flex-row">
                <img src={data.img}/>
                <div style={{marginTop:"-4px"}}>
                  <div className="community-main-contents-card-profile-text"> <span>{data.id}</span> <span>{data.job}</span> <span>{data.carrer}</span> </div>
                  <div style={{fontSize:"12px",color:"#939393"}}>{data.time}</div> 
                </div>
              </div>
              <div style={{paddingLeft:"35px"}}>
                <div className="community-main-contents-card-title">{data.title}</div>
                <div className="community-main-contents-card-text">{data.text}</div>
                <div className="community-main-contents-card-tags flex-row">
                  {data.tags.map((tagsData,index)=>{return( <button key={index}>{tagsData}</button> )})}
                </div>
                <div>
                  <FontAwesomeIcon icon={farThumbsUp} className='community-main-contents-card-btn'/>
                  <span>{data.like}</span>
                  <FontAwesomeIcon icon={farComment} className='community-main-contents-card-btn'/>
                  <span>{data.comment}</span>
                </div>
              </div>
            </div>
          )
        })
      }
      
    </div>
  )
}





export {CommunityPage};