import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Bar, BarChart, CartesianAxis, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { changeHeaderStyle } from "../../store/store";
import { MainFooter, MainHeader } from "../Common/Common";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark as farBookmark } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import './SalaryPage.css';
import { SalaryPageContentCardsData } from "./SalaryPageData";

const SalaryPage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(3));
  },[])


  return(
    <>
      <MainHeader/>
      <SalaryChart/>
      <SalaryMain/>
      <MainFooter/>
    </>
  )
}

const SalaryChart=()=>{

  const chartData = [
    {
      "name": "신입",
      "salary": 3554,
    },
    {
      "name": "1년",
      "salary": 3825,
    },
    {
      "name": "2년",
      "salary": 4092,
    },
    {
      "name": "3년",
      "salary": 4434,
    },
    {
      "name": "4년",
      "salary": 4551,
    },
    {
      "name": "5년",
      "salary": 4963,
    },
    {
      "name": "6년",
      "salary": 5466,
    },
    {
      "name": "7년",
      "salary": 5877,
    },
    {
      "name": "8년",
      "salary": 6194,
    },
    {
      "name": "9년",
      "salary": 6491,
    },
    {
      "name": "10년",
      "salary": 7451,
    },
  ]
  const formatYAxis = (tickItem) => tickItem.toLocaleString();

  return(
    <div className="salary-chart-back">
      <div className="salary-chart-contents flex-row">
        <div className="salary-chart-box">
          <BarChart width={770} height={385} data={chartData} margin={0}>
            <CartesianGrid  horizontal={true} vertical={false}/>
            <XAxis dataKey='name'/>
            <YAxis tickFormatter={formatYAxis} />
            <Tooltip/>
            <Bar dataKey="salary" fill="rgba(0, 0, 0, 0.1)" barSize={20}/>
          </BarChart>
        </div>
        <div className="salary-chart-text-box flex-column">
          <div>
            <button className="salary-chart-text-tags">개발</button>
            <button className="salary-chart-text-tags">파이썬 개발자</button>
          </div>
          <div className="salary-chart-text-expext-salary">신입 파이썬 개발자 예상 연봉</div>
          <div className="salary-chart-text-price">
            <span>3,554</span>
            <span>만원</span>
          </div>
        </div>
      </div>
      <div className="salary-chart-select-contents-back">
        <div className="flex-row salary-chart-select-contents-box">
          <select className="salary-chart-select"><option>개발</option></select>
          <select className="salary-chart-select"><option>파이썬 개발자</option></select>
          <select className="salary-chart-select"><option>신입</option></select>
          <select className="salary-chart-select"><option>0</option></select>
        </div>
      </div>
    </div>
  )
}

const SalaryMain =()=>{
  return(
    <div className="salary-main-back main-container">
      <div className="salary-description">
        * 이 데이터는 채용 정보에 포함된 직무 별 요구 경력(최저, 최대)과 연봉(최저, 최대)을 바탕으로 추정한 예상 연봉 데이터 입니다.
      </div>
      <div className="salary-middle-banner">
        <div>이제 밤새워 채용사이트 보지마세요.</div>
        <div>원티드 매치업에 프로필을 등록하면, 기업의 인사담당자가 직접 면접을 제안합니다.</div>
        <img src="/assets/img/salary-banner.png"/>
        <button>시작하기</button>
      </div>
      <div className="salary-position-cards-title">연봉 업그레이드 포지션</div>
      <SalaryContentCards/>
    </div>
  )
}

const SalaryContentCards=()=>{

  library.add(farBookmark);

  return(
    <div className="salary-position-back">
      <div className="salary-position-contents">
        {
          SalaryPageContentCardsData.slice(0,8).map((data)=>{
            return(
              <div className="salary-position-card" key={data.index}>
                <img src={data.img}/>
                <div>{data.title}</div>
                <div>{data.name}</div>
                <div>{data.area} </div>
                <div>채용보상금 1,000,000원</div>
                <button className="salary-bookmark-btn">
                  <FontAwesomeIcon icon={farBookmark}/>
                </button>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}









export {SalaryPage, SalaryContentCards};







