const TopCarouselData = [
  {
    index:0,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1933%2Fbc16cc3f.jpg&w=1060&q=100",
    title:"90억 투자받은 AI 플랫폼",
    subtitle:"UX/UI 디자이너 & 개발자 채용중",
  },
  {
    index:1,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1920%2Fbcc3df79.jpg&w=1060&q=100",
    title:"상상을 실현시키는 즐거움",
    subtitle:"#요기요 #Tech #Product #적극채용중",
  },
  {
    index:2,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1951%2Ff7091413.jpg&w=1060&q=100",
    title:"디지털 헬스케어 ‘UBcare’",
    subtitle:"개발자 포함 전 직군 적극 채용 중!",
  },
  {
    index:3,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1932%2F5fff8af1.jpg&w=1060&q=100",
    title:"퍼스널 모빌리티 스타트업 '씽씽'",
    subtitle:"개발자 포함 전 직군 적극 채용 중!",
  },
  {
    index:4,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1948%2Fa890bcfc.jpg&w=1060&q=100",
    title:"최근 100억 이상 투자 받은 기업",
    subtitle:"채용 중 포지션 확인하기",
  },
  {
    index:5,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1947%2Fd57066f3.jpg&w=1060&q=100",
    title:"AR&AI 전문기업 아리아엣지",
    subtitle:"개발자 전 직군 채용",
  },
  {
    index:6,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1954%2F7da53e64.jpg&w=1060&q=100",
    title:"'100억 투자유치' XYZ",
    subtitle:"로봇으로 바꾸는 일상, 함께 만들어요",
  },
  {
    index:7,
    img:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2F1901%2F730e6b41.jpg&w=1060&q=100",
    title:"EA코리아",
    subtitle:"'신규 프로젝트' 경력직 채용",
  },
];

const Jobsfeedintro1Data=[
  {
    index:0,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F19%2Fjrvo3iu1po5snygl__1080_790.png&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.cb24f0af.jpg&w=42&q=100",
    name:"밸런스히어로 (트루밸런스)",
    tags:"IT, 컨텐츠",
  },
  {
    index:1,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F5936%2Fdf7si5v1b4fojmjs__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.1d57a6fb.jpg&w=42&q=100",
    name:"아프리카TV",
    tags:"IT, 컨텐츠",
  },
  {
    index:2,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F352%2Fuxxwaeyxhrldywkz__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.ea53594c.jpg&w=42&q=100",
    name:"코나아이",
    tags:"IT, 컨텐츠",
  },
  {
    index:3,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F15528%2Fhcrfmnd777gzrrpj__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.ce305c14.jpg&w=42&q=100",
    name:"인크로스",
    tags:"IT, 컨텐츠",
  },
  {
    index:4,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F15960%2Fe0rqkainje3jhlad__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.e248fad2.jpg&w=42&q=100",
    name:"도도레이블",
    tags:"판매, 유통",
  },
  {
    index:5,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F30392%2Fhftxw0nrm3j1nb2b__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.b3cbe87c.jpg&w=42&q=100",
    name:"코콤",
    tags:"IT, 컨텐츠",
  },
];

const Jobsfeedintro2Data=[
  {
    index:0,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F10460%2Ftvbkxatojo9t8u5c__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.11225c14.jpg&w=42&q=100",
    name:"스마일게이트알피지",
    tags:"IT, 컨텐츠",
  },
  {
    index:1,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F20514%2Fzveczmwxjpunbhrv__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.5b04ad7d.jpg&w=42&q=100",
    name:"비앤에이치코스메틱",
    tags:"제조",
  },
  {
    index:2,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F4561%2Flizfd4gqnm19qlje__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.cd353c18.jpg&w=42&q=100",
    name:"인텔리안테크놀로지스",
    tags:"제조",
  },
  {
    index:3,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F12292%2F8nccvyyrdef3ijsv__1080_790.png&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.eee8293c.jpg&w=42&q=100",
    name:"벨루가브루어리",
    tags:"IT, 컨텐츠",
  },
  {
    index:4,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F26543%2Farzcghk76g9lodaj__1080_790.jpg&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.05d15655.jpg&w=42&q=100",
    name:"에이엠에스",
    tags:"제조",
  },
  {
    index:5,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F34279%2Fdlqlhehjlxzzk5bx__1080_790.png&w=520&q=100",
    subImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fwdes%2F0_4.01abcfac.jpg&w=42&q=100",
    name:"매니코어소프트",
    tags:"IT, 컨텐츠",
  },
];

const JobsfeedThemaData=[
  {
    index:0,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2Fthemes%2F370_3e73ebe9.jpg&w=520&q=100",
    subImg:"/assets/img/thema1.png",
    subImgLength:"342px",
    title:"최근 100억 투자 유치 기업 - 경영/비즈니스 포지션",
    subTitle:"2023년 로켓 성장 준비 완료! 성장 가능성 큰 기업들이 채용 중인 포지션을 모아봤어요.",
    add:"+23",
  },
  {
    index:1,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2Fthemes%2F367_9c1a57c8.jpg&w=520&q=100",
    subImg:"/assets/img/thema2.png",
    subImgLength:"304px",
    title:"2023 겨울 인턴십",
    subTitle:"인턴십도 원티드에서, 다양한 경험과 커리어를 시작하세요!",
    add:null,
  },
  {
    index:2,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2Fthemes%2F370_3e73ebe9.jpg&w=520&q=100",
    subImg:"/assets/img/thema1.png",
    subImgLength:"342px",
    title:"최근 100억 투자 유치 기업 - 경영/비즈니스 포지션",
    subTitle:"2023년 로켓 성장 준비 완료! 성장 가능성 큰 기업들이 채용 중인 포지션을 모아봤어요.",
    add:"+23",
  },
  {
    index:3,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2Fthemes%2F367_9c1a57c8.jpg&w=520&q=100",
    subImg:"/assets/img/thema2.png",
    subImgLength:"304px",
    title:"2023 겨울 인턴십",
    subTitle:"인턴십도 원티드에서, 다양한 경험과 커리어를 시작하세요!",
    add:null,
  },
  {
    index:4,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2Fthemes%2F370_3e73ebe9.jpg&w=520&q=100",
    subImg:"/assets/img/thema1.png",
    subImgLength:"342px",
    title:"최근 100억 투자 유치 기업 - 경영/비즈니스 포지션",
    subTitle:"2023년 로켓 성장 준비 완료! 성장 가능성 큰 기업들이 채용 중인 포지션을 모아봤어요.",
    add:"+23",
  },
  {
    index:5,
    mainImg:"https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fbanners%2Fthemes%2F367_9c1a57c8.jpg&w=520&q=100",
    subImg:"/assets/img/thema2.png",
    subImgLength:"304px",
    title:"2023 겨울 인턴십",
    subTitle:"인턴십도 원티드에서, 다양한 경험과 커리어를 시작하세요!",
    add:null,
  },
]





export {TopCarouselData, Jobsfeedintro1Data, Jobsfeedintro2Data, JobsfeedThemaData};



