import { MainHeader } from "../Common/Common";
import './ResumePageLogin.css';

import { faExclamationCircle, faFileUpload, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faCopy as farCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { changeHeaderStyle } from "../../store/store";
import axios from "axios";

const ResumePageLogin = ()=>{

  library.add(farCopy);

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(4));
  },[])
  

  // 로그인했을때 이력서 데이터 가져오기
  const [resumeGetArr, setResumeGetArr] = useState('');

  useEffect( ()=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/resumes`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        console.log(res.data);
        setResumeGetArr(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  },[])

  
  // 새이력서 작성 버튼 함수
  const WriteResumeBtn = ()=>{
    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/resumes`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.post(url, {}, {headers:config})
      .then((res)=>{
        console.log("새이력서작성완료");
        console.log(res.data);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  }


  return(
    <>
      <MainHeader/>
      <div className="resume-login-back">
        <div className="main-container">
          <div className="resume-login-top-banner">
            <img src="https://static.wanted.co.kr/career_connect/banner-back-pc-common.png"/>
            <img src="https://static.wanted.co.kr/career_connect/trio.png"/>
            <button className="resume-login-top-banner-btn flex-row">
              <div>이력서 작성이 쉬워진다! 1분 만에 채워지는 경력정보</div>
              <div>내 경력 불러오기</div>
            </button>
          </div>
          <div className="resume-login-contents-box">
            <div className="resume-login-contents-title flex-row">
              <div>최근 문서</div>
              <div>원티드 이력서 소개 <FontAwesomeIcon icon={faExclamationCircle}/></div>
            </div>
            <div className="main-container resume-login-contents-cards-back">
              <button className="resume-login-write-box" onClick={()=>{WriteResumeBtn();}}>
                <div><FontAwesomeIcon icon={farCopy} style={{color:"#fff",width:"24px",height:"24px"}}/></div>
                <p>새 이력서 작성</p>
              </button>
              <button className="resume-login-upload-box">
                <div><FontAwesomeIcon icon={faFileUpload} style={{color:"#666",width:"26px",height:"28px"}}/></div>
                <p>파일 업로드</p>
              </button>

              {
                resumeGetArr != ''?
                resumeGetArr.map((data, index)=>{
                  return(
                    <button className="resume-login-resume-box flex-column" key={index}>
                      <div className="resume-login-resume-box-text">
                        {
                          data.matchUpTrueFalse != 0? <div>매치업 이력서</div> : <div style={{backgroundColor:"#fff"}}></div>
                        }
                        <div>{data.resumeTitle}</div>
                        <div>{data.updatedAt}</div>
                      </div>
                      <div className="resume-login-resume-box-bottom flex-row">
                        <div>한</div>
                        <div>{data.resumeCompletion}</div>
                        <div><FontAwesomeIcon icon={faEllipsisV} style={{width:"6px",height:"18px"}}/></div>
                      </div>
                    </button>
                  )
                })
                : null
              }

              {/* <button className="resume-login-resume-box flex-column">
                <div className="resume-login-resume-box-text">
                  <div>매치업 이력서</div>
                  <div>사용자의 관점에서 개발하고 발전하는 OOO 지원자입니다.</div>
                  <div>2023.02.07</div>
                </div>
                <div className="resume-login-resume-box-bottom flex-row">
                  <div>한</div>
                  <div>작성완료</div>
                  <div><FontAwesomeIcon icon={faEllipsisV} style={{width:"6px",height:"18px"}}/></div>
                </div>
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export {ResumePageLogin};




