import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MainHeader } from '../Common/Common';
import './MyBookMarkPage.css';

const MyBookMarkPage = ()=>{
  return(
    <>
      <MainHeader/>
      <BookMarkMain/>
    </>
  )
}

const BookMarkMain =()=>{
  library.add(farBookmark);

  // 불러온 북마크 정보 저장할 state
  const [BookMarkDataState, setBookMarkDataState] = useState('');
  // 북마크 선택시 변할 버튼 스타일을 위한 state
  const [BookMarkBtnIndex, setBookMarkBtnIndex] = useState([]);

  // 내 아이디에서 북마크 불러오기
  useEffect( ()=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        // console.log(res.data);
        setBookMarkDataState(res.data.result); // 카드 데이터 저장
        
        // 버튼 스타일을 위한 인덱스 저장
        let bookmarkBtnIndexArr = [];
        res.data.result.map((data)=>{ bookmarkBtnIndexArr.push(data.recruitmentPostId); }) 
        setBookMarkBtnIndex(bookmarkBtnIndexArr);  // 현재들어있는 bookmark의 id 를 저장
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  },[])

  // 북마크 클릭시 : 내 북마크 리스트 조회하기
  const ShowMyBookMarkList = (PostId)=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        // console.log(res.data);
        
        // 현재 누른 포스트의 id가 내 bookmark에 저장된 포스트의 id에 포함되는지 찾기
        let findBookList = res.data.result.find( function(data){ return data.recruitmentPostId === PostId } );
        // 포함되어있다면 삭제 , 포함되어 있지 않다면 추가 
        if (findBookList !== undefined){
          DeleteMyBookMarkList(PostId);
          // console.log("있는데요");
        } else if(findBookList === undefined) {
          AddMyBookMarkList(PostId);
          // console.log("없는데요");
        }

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  };

  // 북마크 클릭시 : 내 북마크 리스트에 추가하기
  const AddMyBookMarkList = (PostId)=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${PostId}/plus-bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.post(url,{},{headers:config})
      .then((res)=>{
        // 내 리스트에 추가했을때 빨간색 북마크로 표시해주기
        let plusBookMarkBtnIndex = [...BookMarkBtnIndex];
        plusBookMarkBtnIndex.push(PostId);
        setBookMarkBtnIndex( plusBookMarkBtnIndex );  

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  };

  // 북마크 클릭시 : 내 북마크 리스트에서 삭제하기
  const DeleteMyBookMarkList = (PostId)=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${PostId}/minus-bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.patch(url,{},{headers:config})
      .then((res)=>{
        // 인덱스 삭제하기
        let minusBookMarkBtnIndex = [...BookMarkBtnIndex];
        let filterdminusBookMarkBtnIndex = minusBookMarkBtnIndex.filter((element)=>element !== PostId );
        setBookMarkBtnIndex( filterdminusBookMarkBtnIndex );  

        // 삭제한 인덱스 화면에서 없애기
        let minusBookMarkData = [...BookMarkDataState];
        let filterminusBookMarkData = minusBookMarkData.filter( (element)=> element.recruitmentPostId !== PostId  )
        setBookMarkDataState( filterminusBookMarkData );

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  }




  return(
    <div>
      <div className='main-container bookmark-main-back'>
        <div className='bookmark-main-title'>북마크</div>

        {
          BookMarkDataState !== '' && BookMarkDataState.length !==0?
          <div className="jobslist-new-position-back">
            <div className="jobslist-new-position-contents">
              {
                BookMarkDataState.map((data, index)=>{
                return(
                      <Link to='' key={data.recruitmentPostId}>
                        <div className="jobslist-new-position-card">
                          <img src={data.companyRepresentativeImg}/>
                          <div>{data.positionName}</div>
                          <div>{data.companyName}</div>
                          <div>{data.country}</div>
                          <div>채용보상금 1,000,000원</div>
                          {/* <button className="jobslist-bookmark-btn">
                            <FontAwesomeIcon icon={farBookmark}/>
                          </button> */}
                          {
                            BookMarkBtnIndex.includes(data.recruitmentPostId) ?
                            <button className="jobslist-bookmark-btn" style={{color:"red"}}
                              onClick={()=>{
                                // 내 북마크 리스트 조회 -> 삭제 or 추가
                                ShowMyBookMarkList(data.recruitmentPostId);
                              }}>
                              <FontAwesomeIcon icon={faBookmark}/>
                            </button>
                            : 
                            <button className="jobslist-bookmark-btn"
                              onClick={()=>{
                                // 내 북마크 리스트 조회 -> 삭제 or 추가
                                ShowMyBookMarkList(data.recruitmentPostId);
                              }}>
                              <FontAwesomeIcon icon={farBookmark}/>
                            </button>
                          }
                        </div>
                      </Link>
                    )
                })
              }
            </div>
          </div>
          : <div className='bookmark-main-contents-box'>아직 북마크한 포지션이 없습니다.</div>
        }

      </div>
    </div>
  )
}



export {MyBookMarkPage};







