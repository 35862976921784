const SalaryPageContentCardsData = [
  {
    index: 0,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F13307%2Fo8vpozkmjfthdk2y__400_400.png&w=400&q=75",
    title: "프로덕트 오너 (PO / PM / 서비스 기획)",
    name: "피클플러스",
    area: "서울 · 한국",
  },
  {
    index: 1,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F5380%2Fvd2jueavxoxdrb5l__400_400.jpg&w=400&q=75",
    title: "웹디자이너",
    name: "모던하우스(MH&Co.)",
    area: "서울 · 한국",
  },
  {
    index: 2,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F7111%2Fydhlckrrli3dyd3i__400_400.jpg&w=400&q=75",
    title: "[미니창고 다락] 인사 운영 매니저",
    name: "세컨신드롬",
    area: "서울 · 한국",
  },
  {
    index: 3,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F4095%2Fyordqgyngzdohelg__400_400.jpg&w=400&q=75",
    title: "[커머스그룹] 바이럴 마케터",
    name: "패스트뷰",
    area: "서울 · 한국",
  },
  {
    index: 4,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F15562%2Fqc8jfxxhlmwlnv4u__400_400.jpg&w=400&q=75",
    title: "시니어 개발자(Tech Lead)",
    name: "핌아시아",
    area: "서울 · 한국",
  },
  {
    index: 5,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F28968%2F7xeljrrobswdffeq__400_400.jpg&w=400&q=75",
    title: "[백엔드] Java Spring boot 개발자",
    name: "에이로직",
    area: "서울 · 한국",
  },
  {
    index: 6,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F34784%2F5ov5izlmrohipzne__400_400.jpg&w=400&q=75",
    title: "Accountant & Administrative officer",
    name: "애드테크이노베이션",
    area: "한국",
  },
  {
    index: 7,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F32030%2Fkjswex5nkm6fe6o3__400_400.jpg&w=400&q=75",
    title: "컨텐츠 디자이너",
    name: "에이지엣랩스",
    area: "서울 · 한국",
  },
  {
    index:8,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F12621%2Fvylslm1flmjnk6yx__400_400.jpg&w=400&q=75",
    title: "back-end 개발자",
    name: "볼드나인",
    area: "서울 · 한국",
  },
  {
    index:9,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F24017%2Fkjbrynkzafbneweg__400_400.jpg&w=400&q=75",
    title: "디자인 팀원 (시각/그래픽/편집)",
    name: "비핸즈",
    area: "서울 · 한국",
  },
  {
    index:10,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F8487%2F3e6nnlaufiptbxot__400_400.jpg&w=400&q=75",
    title: "인프라 엔지니어",
    name: "엔터플",
    area: "서울 · 한국",
  },
  {
    index:11,
    img: "https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fstatic.wanted.co.kr%2Fimages%2Fcompany%2F32207%2F3waawliai3iuvqbv__400_400.png&w=400&q=75",
    title: "(2년이상) NLP(자연어처리) 인공지능 개발자",
    name: "텔레픽스",
    area: "서울 · 한국",
  },
];

export {SalaryPageContentCardsData};

