import { MainFooter, MainHeader } from "../Common/Common";
import './MainPage.css'
import { InsightCardData, TopCarouselData, MainEventCardData } from "./MainPageData";

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faChevronDown, faArrowLeftRotate, faXmark, faSliders } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { changeHeaderStyle } from "../../store/store";
import axios from "axios";

const MainPage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(0));
  },[])

  return(
    <>
      <MainHeader/>
      
      <div className="main-back">
        <TopCarousel/>
        <Insight/>
        <RecommendBanner/>
        <EventBanner/>
        <ApplyInformation/>
      </div>

      <MainFooter/>

    </>
  )
}



const TopCarousel = ()=>{

  const [getTopCarouselData, setGetTopCarouselData] = useState(''); // 여기에 get해온 데이터 다 가져옴 // 이거만 고치면 됨

  // 데이터 가져오는 함수
  useEffect( ()=>{
    const url = 'https://prod.risingserver13forever.shop/app/main-events';
    try{
      axios.get(url)
      .then((res)=>{
        // console.log(res.data.result);
        setGetTopCarouselData(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[])

  const size = useWindowSize(); // 현재 윈도우 사이즈
  const [translateSize, setTranslateSize] = useState(1); // 첫페이지 
  const [transitionTime, setTransitionTime] = useState(500); // 변하는 시간 조절 -> 무한캐러샐을위해서

  // 사이즈 변경
  let changeValue = 0;
  if(size.width>1108){
    changeValue = 1*0.5*(size.width-1108) - 1084*translateSize ;  // -1084*n
  } else{
    changeValue = 0;
  }

  // 캐러샐 데이터 길이
  const TopCarouselDataLength = getTopCarouselData.length;  // TopCarouselData

  // 데이터 앞뒤로 한개씩 추가하기
  const showTopCarouselData = [ ...getTopCarouselData.slice(TopCarouselDataLength-1, TopCarouselDataLength),  // TopCarouselData
                                ...getTopCarouselData,                     // TopCarouselData
                                ...getTopCarouselData.slice(0,1) ] ;       // TopCarouselData
  // 전체 길이
  const contentsWidth = (getTopCarouselData.length+2)*1084;     // TopCarouselData

  // btn 클릭 함수 - prev
  const swiperBtnPrevFunc =()=>{
    setTransitionTime(500) // 슬라이더 이동 시간
    setTranslateSize(translateSize-1);  // 페이지 이동
    
    if(translateSize<=1){
      setTimeout(()=>{
        setTransitionTime(0); // 이동시간 0
        setTranslateSize(showTopCarouselData.length-2)
      },450)
    }
  }
  // btn 클릭 함수 - next
  const swiperBtnNextFunc =()=>{
    setTransitionTime(500) // 슬라이더 이동 시간
    setTranslateSize(translateSize+1);  // 페이지 이동
    
    if(translateSize===showTopCarouselData.length-2){
      setTimeout(()=>{
        setTransitionTime(0); // 이동시간 0
        setTranslateSize(1);
      },450)
    }
  }

  // 3초마다 슬라이드 바꾸기
  useEffect(()=>{
    // console.log(translateSize);
    const timeoutId = setTimeout(()=>{
      swiperBtnNextFunc();
      // setTranslateSize(translateSize+1);
    },4000)
    return ()=> clearTimeout(timeoutId);
  },[translateSize])


  return(
    <div className="swiper-back">
      <div className="swiper-contents flex-row"
        style={{transform:`translateX(${changeValue}px)`, width:`${contentsWidth}px`, transition:`all ${transitionTime}ms`}}>
        {
          // {data.representativeImg} {data.titleForAD} {data.introductionForAd}
          showTopCarouselData.map((data, index)=>{
            return(
              <div className="swiper-card" key={index}>
                <img src={data.representativeImg}/>  
                <div className="swiper-text-box">
                  <div className="swiper-text-box-title">{data.titleForAD}</div>
                  <div className="swiper-text-box-sub-title">{data.introductionForAd}</div>
                  <hr className="swiper-text-box-hr"/>
                  <Link to='/' className="swiper-text-box-link">
                    <div>
                      <span>바로가기</span>
                      <FontAwesomeIcon icon={faChevronRight} style={{marginLeft:"4px"}}/>
                    </div>
                  </Link>
                </div>
              </div>
            )
          })
        }
      </div>
      <button className="top-swiper-btn top-swiper-before-btn" onClick={()=>{
          swiperBtnPrevFunc();
        }}>
        <span><svg className="top-swiper-btn-svg" viewBox="0 0 18 18"><path d="m6.045 9 5.978-5.977a.563.563 0 1 0-.796-.796L4.852 8.602a.562.562 0 0 0 0 .796l6.375 6.375a.563.563 0 0 0 .796-.796L6.045 9z"></path></svg></span>
      </button>
      <button className="top-swiper-btn top-swiper-next-btn" onClick={()=>{
          swiperBtnNextFunc();
        }}>
        <span><svg className="top-swiper-btn-svg" viewBox="0 0 18 18"><path d="m11.955 9-5.978 5.977a.563.563 0 0 0 .796.796l6.375-6.375a.563.563 0 0 0 0-.796L6.773 2.227a.562.562 0 1 0-.796.796L11.955 9z"></path></svg></span>
      </button>
    </div>
  )
}

// Hook
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}





const Insight =()=>{

  // 인사이트 상단 캐러샐
  const insightCarouselContentBtn = ['조직문화','인간관계','리더십','커리어고민','라이프스타일','프리랜서','IT/기술','취업/이직',
                                    '경영/전략','노무','브랜딩','데이터','MD','콘텐츠 제작','서비스 기획','UX/UI','개발','HR','디자인','마케팅'];
  const [insightCarouselBtnURL, setInsightCarouselBtnURL] = useState('culture');

  const [insightCarouselBtn, setInsightcarouselBtn] = useState(["none","flex"]);

  const [insightCarouselBtnStyle, setInsightcarouselBtnStyle] = useState(['insight-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'
                                                                          ,'insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'
                                                                          ,'insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'
                                                                          ,'insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click']);
  const [insightCarouselCurrentIndex, setInsightCarouselCurrentIndex] = useState(0); // 현재 어느 버튼을 눌렀는지
  const [insightCarouselpx, setInsightCarouselpx] = useState(0); // 이동할 px

  // 각각의 카테고리 버튼 눌렀을때 이동
  useEffect(()=>{
    if(insightCarouselCurrentIndex<=4){
      setInsightCarouselpx(0);
      setInsightcarouselBtn(["none","flex"]);
    } if(insightCarouselCurrentIndex>4){
      setInsightCarouselpx(-400);
      setInsightcarouselBtn(["flex","flex"]);
    } if(insightCarouselCurrentIndex>9){
      setInsightCarouselpx(-800);
      setInsightcarouselBtn(["flex","flex"]);
    } if(insightCarouselCurrentIndex>14){
      setInsightCarouselpx(-1050);
      setInsightcarouselBtn(["flex","none"]);
    }
  },[insightCarouselCurrentIndex]);

  // 버튼으로 이동하기
  const CarouselBtnFunc = (type)=>{
    if(insightCarouselpx==0 && type==1){
      setInsightCarouselpx(-400);
      setInsightcarouselBtn(["flex","flex"]);
    } if(insightCarouselpx==-400 && type==1){
      setInsightCarouselpx(-800);
      setInsightcarouselBtn(["flex","flex"]);
    } if(insightCarouselpx==-800 && type==1){
      setInsightCarouselpx(-1050);
      setInsightcarouselBtn(["flex","none"]);
    }
    if(insightCarouselpx==-1050 && type==-1){
      setInsightCarouselpx(-800);
      setInsightcarouselBtn(["flex","flex"]);
    } if(insightCarouselpx==-800 && type==-1){
      setInsightCarouselpx(-400);
      setInsightcarouselBtn(["flex","flex"]);
    } if(insightCarouselpx==-400 && type==-1){
      setInsightCarouselpx(0);
      setInsightcarouselBtn(["none","flex"]);
    }
  }


  // 불러온 데이터 담을 state
  const [getCardData, setGetCardData] = useState('');

  // 데이터 불러오기
  useEffect( ()=>{
    const url = `https://prod.risingserver13forever.shop/app/insights?tagId=${insightCarouselBtnURL}`;
    try{
      axios.get(url)
      .then((res)=>{
        // console.log(res.data.result);
        setGetCardData(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[insightCarouselCurrentIndex])


  // 데이터 리프래쉬
  const refreshBtnFunc = ()=>{
    const url = `https://prod.risingserver13forever.shop/app/insights/${insightCarouselBtnURL}/refresh`;
    try{
      axios.get(url)
      .then((res)=>{
        // console.log(res.data.result);
        setGetCardData(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  }


  // 카드 12개 -> 20개 , 버튼 화살표 -> 새로고침
  const [InsightCardDataPlusBtn, setInsightCardDataPlusBtn] = useState([12, faChevronDown]);

  return(
    <div className="insight-back">
      <div className="insight-contents main-container">
        <div className="flex-row insight-title-box">
          <div className="insight-title">나에게 필요한 커리어 인사이트</div>
          <button className="insight-title-btn" type="button"><svg width="24" height="24" viewBox="0 0 17 17"><defs><filter id="bfoh3u0w3a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.600000 0 0 0 0 0.600000 0 0 0 0 0.600000 0 0 0 1.000000 0"></feColorMatrix></filter></defs><g fill="none" fillRule="evenodd"><g><g><g transform="translate(-1080 -374) translate(1080 374)"><g><path stroke="#999" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M9.421 13.334c-.736.277-1.535.43-2.368.43-3.706 0-6.71-3.005-6.71-6.711 0-3.707 3.004-6.71 6.71-6.71 1.853 0 3.53.75 4.745 1.965 1.214 1.214 1.965 2.892 1.965 4.745 0 1.853-.75 3.53-1.965 4.745" transform="translate(1 1)"></path><path fill="#999" d="M6.382 10.408c0-.371.3-.671.67-.671.371 0 .672.3.672.67 0 .372-.3.672-.671.672-.37 0-.671-.3-.671-.671" transform="translate(1 1) rotate(-180 7.053 10.408)"></path><path stroke="#999" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M5.04 5.655c0-1.08.901-1.958 2.013-1.958 1.11 0 2.013.877 2.013 1.958 0 1.08-1.007 1.957-2.013 1.957v.783" transform="translate(1 1)"></path></g></g></g></g></g></svg></button>
        </div>
        
        <div className="insight-carousel-back flex-row">
          <div className="insight-carousel-box">
            <div className="insight-carousel-contents" style={{transform:`translateX(${insightCarouselpx}px)`}}>
              {insightCarouselContentBtn.map((data,index)=>{
                return(
                  <button key={index} className={`insight-carousel-content-btn ${insightCarouselBtnStyle[index]}`}
                    onClick={()=>{
                        let currentBtn = ['insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'
                        ,'insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'
                        ,'insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'
                        ,'insight-not-click','insight-not-click','insight-not-click','insight-not-click','insight-not-click'];
                        currentBtn[index] = 'insight-click';
                        setInsightcarouselBtnStyle( currentBtn ); // 누른 버튼 스타일 변경 
                        setInsightCarouselCurrentIndex( index ); // 현재 몇번버튼 눌렀는지

                        // 데이터 불러오기 위한 코드
                        let urlData = ['culture','relationship','leadership','career','lifestyle','freelancer','techit',
                        'getjob','sales','labor','branding','data','md','contents',
                        'planning','uiux','dev','hr','design','marketing'];
                        setInsightCarouselBtnURL(urlData[index]);
                    }}>
                  {data}
                  </button>  
                )
              })}
            </div>
            <div className="insight-carousel-move-btn-box move-btn-left-box" style={{display:`${insightCarouselBtn[0]}`}}>
              <button className="insight-carousel-move-btn" onClick={()=>{ CarouselBtnFunc(-1); }}>
                <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
            </div>
            <div className="insight-carousel-move-btn-box move-btn-right-box" style={{display:`${insightCarouselBtn[1]}`}}>
              <button className="insight-carousel-move-btn" onClick={()=>{ CarouselBtnFunc(1); }}>
                <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            </div>
          </div>

          <div>
            <button type="button" className="insight-carousel-open-btn"><svg width="18" height="18" viewBox="0 0 24 24"><path fill="currentColor" d="M12 10a2 2 0 1 1-.001 4.001A2 2 0 0 1 12 10zm7 0a2 2 0 1 1-.001 4.001A2 2 0 0 1 19 10zM5 10a2 2 0 1 1-.001 4.001A2 2 0 0 1 5 10z"></path></svg></button>
          </div>
        </div>
        
        <div className="insight-cards-back">
          {
            // InsightCardData.slice(0,InsightCardDataPlusBtn[0])

            getCardData!=''?
            // getCardData.slice(0,InsightCardDataPlusBtn[0])
            // {data.representativeContentsImg}  {data.eventTitle}{data.insightTitle}  {data.contents}  {data.producerProfileImg}  {data.producerName}
            getCardData.slice(0,InsightCardDataPlusBtn[0]).slice(0,InsightCardDataPlusBtn[0]).map((data,index)=>{
              return(
                <Link to='/' key={index}>
                  <div className="insight-card-img-box">
                    <img className="insight-card-img" src={data.representativeContentsImg}/>
                  </div>
                  <div className="insight-card-title">
                    {data.eventTitle}{data.insightTitle}
                  </div>
                  <div className="insight-card-subtitle">
                    {data.contents}
                  </div>
                  <div className="insight-card-brand">
                    <img className="insight-card-brand-img" src={data.producerProfileImg}/>
                    <span>{data.producerName}</span>
                  </div>
                </Link>
              )
            })
            : null
            
          }

        </div>
        <div className="insight-plus-btn-box">
          <button className="insight-plus-btn" onClick={()=>{
            if(InsightCardDataPlusBtn[0]===12){
              setInsightCardDataPlusBtn([21,faArrowLeftRotate]);
            } else if(InsightCardDataPlusBtn[0]===21){
              refreshBtnFunc();
            }
            }}>
            <span>더 많은 콘텐츠 보기</span>
            <FontAwesomeIcon  icon={InsightCardDataPlusBtn[1]} style={{marginLeft:"6px"}}/>
          </button>
        </div>

      </div>
    </div>
  )
}

const RecommendBanner =()=>{
  return(
    <div className="main-container">
      <div className="type-test-banner-back">
        <div>내 유형과 가장 어울리는 회사는?</div>
        <div>유형테스트 하러가기<FontAwesomeIcon icon={faChevronRight} className='icon-style'/>
        </div>
      </div>

      <div className="recommend-creator-banner-back">
        <div>
          <div className="recommend-creator-banner-title">
            즐겨보는 크리에이터의 글도 추천하고 싶다면?
          </div>
          <Link to="/" className="recommend-creator-banner-link">
            <span>크리에이터 추천하기</span>
            <FontAwesomeIcon icon={faChevronRight} className='icon-style'/>
          </Link>
        </div>
        <img style={{width:"112px"}} src="https://image.wanted.co.kr/optimize?src=https%3A%2F%2Fimage.wanted.co.kr%2Fuserweb%2Fcareerhome%2Fcreator-application.png&w=224&q=90"/>
        <button className="recommend-creator-banner-cancel">
          <FontAwesomeIcon icon={faXmark}/>
        </button>

      </div>
      <div className="recommend-job-banner-back">
        <div>
          <span>직군/직무를 입력하면 관련 콘텐츠를 추천해 드려요.👀 </span>
          <FontAwesomeIcon icon={faChevronRight}/> 
        </div>
      </div>
    </div>
  )
}




const EventBannerCardTag = styled.div`
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  padding: 0 6px;
  color: ${props=>props.tagColor};
  border-color : ${props=>props.tagColor};
  border: 1px solid;
  width:fit-content;
  margin-top:12px;
`;
const EventBanner =()=>{

  // 불러온 데이터 담을 state
  const [getCardData, setGetCardData] = useState('');

  // 버튼 스타일
  const [eventCarouselBtn, setEventcarouselBtn] = useState(["disabled-btn","abled-btn"]);


  // 데이터 불러오기
  useEffect( ()=>{
    const url = "https://prod.risingserver13forever.shop/app/recommended-events";
    try{
      axios.get(url)
      .then((res)=>{
        // console.log(res.data.result);
        setGetCardData(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[])

  // 캐러샐 움직이기
  const [eventBannerPx, setEventBannerPx] = useState(0);
  const eventMoveBtn = (type)=>{
    if(type==1 && eventBannerPx==0){
      setEventBannerPx(-1060);
      setEventcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==1 && eventBannerPx==-1060){
      setEventBannerPx(-1060*2);
      setEventcarouselBtn(['abled-btn','disabled-btn']);
    }
    if(type==-1 && eventBannerPx==-1060*2){
      setEventBannerPx(-1060);
      setEventcarouselBtn(['abled-btn','abled-btn']);
    } else if(type==-1 && eventBannerPx==-1060){
      setEventBannerPx(0);
      setEventcarouselBtn(['disabled-btn','abled-btn']);
    }
  };

  return(
    <div className="event-banner-back main-container">
      <div className="event-banner-title-box flex-row">
        <button className={`event-banner-btn ${eventCarouselBtn[0]}`} onClick={()=>{eventMoveBtn(-1);}}>
          <FontAwesomeIcon icon={faChevronLeft}/>
        </button>
        <div>
          <div className="event-banner-title">커리어 성장을 위한 맞춤 이벤트</div>
          <Link to='/' className="event-banner-sub-title">
            <span>이벤트 전체 보러가기</span>
            <FontAwesomeIcon icon={faChevronRight}  className="icon-style" style={{fontSize:"13px"}}/>
          </Link>
        </div>
        <button className={`event-banner-btn ${eventCarouselBtn[1]}`} onClick={()=>{eventMoveBtn(1);}}>
          <FontAwesomeIcon icon={faChevronRight}/>
        </button>
      </div>
      <div className="event-banner-swiper-back">
        <div className="event-banner-swiper-contents flex-row" style={{transform:`translateX(${eventBannerPx}px)`}}>
          {
            getCardData != ''?
            getCardData.map((data,index)=>{
              return(          
              <div className="event-banner-card" key={data.eventId}>
                <img src={data.eventRepresentativeImg}/>
                <EventBannerCardTag tagColor={data.eventType}>{data.eventType}</EventBannerCardTag>
                <div className="event-banner-card-title">{data.eventName}</div>
                {
                  data.date != null?
                  <div className="event-banner-card-date">{data.closeTime}</div>
                  : null
                }
              </div>)
            })
            :null
          }
        </div>
      </div>
    </div>
  )
}


const ApplyInformation = ()=>{
  return(
    <div className="apply-info-back main-container">
      <div className="apply-info-divider"></div>
      <div className="apply-info-title">채용정보를 찾고 계셨나요?</div>
      <div className="flex-row apply-info-contents-box">
        <div className="apply-info-content flex-column">
          <Link to='/' className="apply-info-link">
            <span><svg className="apply-info-icon"><path d="M27.96 29.374a1 1 0 1 0 1.414-1.414l-8.667-8.667-.338-.294a1 1 0 0 0-1.357.05 9 9 0 1 1 2.24-3.676 1 1 0 1 0 1.909.6 11.01 11.01 0 0 0 .506-3.306c0-6.076-4.925-11-11-11-6.076 0-11 4.924-11 11 0 6.074 4.925 11 11 11 2.615 0 5.087-.918 7.04-2.549l8.253 8.256z"></path></svg></span>
          </Link>
          <button className="apply-info-btn">
            <span>채용공고</span>
            <FontAwesomeIcon icon={faChevronRight} className='icon-style'/>
          </button>
        </div>
        <div className="apply-info-content flex-column">
          <Link to='/'>
            <span><svg className="apply-info-icon"><path d="M16.46 12.565c-.631.802-1.025 1.667-1.039 2.59-.02 1.45.793 2.538 2.286 3.154l.116.038c3.487.898 5.788 2.958 5.788 5.03l.014 1.226c0 .026-.194.188-.35.189H4.662c-.136 0-.274-.122-.274-.178l-.014-1.248c0-1.997 2.086-3.93 5.412-4.868a4.468 4.468 0 0 0 1.275-.538c.775-.474 1.316-1.135 1.448-2.014a.875.875 0 0 0-1.731-.259c-.044.292-.256.552-.629.779a2.898 2.898 0 0 1-.795.337c-4.04 1.137-6.73 3.632-6.73 6.573l.014 1.248c0 1.062.965 1.918 2.024 1.918h18.613c1.061-.003 2.099-.871 2.099-1.949l-.014-1.227c0-3.003-2.9-5.612-7.039-6.697-.825-.354-1.16-.816-1.15-1.49.006-.473.248-1.003.663-1.53.272-.346.545-.606.71-.736l.087-.08c1.233-1.29 2.03-3 2.03-4.666 0-3.648-3.053-6.709-6.692-6.709-3.639 0-6.692 3.061-6.692 6.709 0 1.57.443 2.914 1.357 4.048a.875.875 0 1 0 1.363-1.097c-.655-.813-.97-1.768-.97-2.951 0-2.682 2.27-4.959 4.942-4.959s4.941 2.276 4.941 4.959c0 1.162-.587 2.437-1.506 3.417-.258.211-.6.545-.944.981z"></path></svg></span>
          </Link>
          <button className="apply-info-btn">
            <span>내프로필</span>
            <FontAwesomeIcon icon={faChevronRight} className='icon-style'/>
          </button>
        </div>
        <div className="apply-info-content flex-column">
          <Link to='/'>
            <span><svg className="apply-info-icon"><path d="M10.208 25.083a.875.875 0 0 1-.875-.875v-6.125h-1.75v6.125a.875.875 0 0 1-.875.875H2.042a.875.875 0 0 1-.875-.875V4.375c0-.483.391-.875.875-.875h12.833c.483 0 .875.392.875.875v3.791h9.625c.446 0 .814.334.868.766l.007.11v10.5a.875.875 0 0 1-1.75 0V9.917h-8.75v13.416h9.625a.875.875 0 0 1 0 1.75H10.208zM14 5.25H2.917v18.083h2.916v-6.125c0-.483.392-.875.875-.875h3.5c.484 0 .875.392.875.875v6.125H14V5.25zm4.375 10.792c.644 0 1.167.522 1.167 1.166v1.167a1.167 1.167 0 0 1-2.334 0v-1.167c0-.644.523-1.166 1.167-1.166zm0-4.667c.644 0 1.167.522 1.167 1.167v1.166a1.167 1.167 0 0 1-2.334 0v-1.166c0-.645.523-1.167 1.167-1.167zm-11.5 0a1 1 0 0 1 1 1v1.5a1 1 0 0 1-1 1h-.333a1 1 0 0 1-1-1v-1.5a1 1 0 0 1 1-1h.333zm0-4.667a1 1 0 0 1 1 1v1.5a1 1 0 0 1-1 1h-.333a1 1 0 0 1-1-1v-1.5a1 1 0 0 1 1-1h.333z"></path></svg></span>
          </Link>
          <button className="apply-info-btn">
            <span>매치업</span>
            <FontAwesomeIcon icon={faChevronRight} className='icon-style'/>
          </button>
        </div>
        <div className="apply-info-content flex-column">
          <Link to='/'>
            <span>
              <FontAwesomeIcon icon={faSliders} className='apply-info-icon'/>
            </span>
          </Link>
          <button className="apply-info-btn">
            <span>직군별 연봉</span>
            <FontAwesomeIcon icon={faChevronRight} className='icon-style'/>
          </button>
        </div>

      </div>
    </div>
  )
}






export {MainPage, TopCarousel};






