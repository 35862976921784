import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeHeaderStyle } from "../../store/store";
import { MainFooter, MainHeader } from "../Common/Common";
import './AiscorePage.css';

import { faPencil, faArrowUpFromBracket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const AiscorePage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(7));
  },[])


  return(
    <>
      <MainHeader/>
      <AiscoreMain/>
      <MainFooter/>
    </>
  )
}

const AiscoreMain = ()=>{
  return(
    <>
      <div className="aiscore-main-top-banner-back">
        <div>이력서만 등록해도 나에게 딱 맞는 포지션 추천!</div>
        <div>내 이력서를 분석하여 합격률이 높은 포지션을 알려드려요.<br/>서류합격률은 이력서에 작성된 내용과 포지션과의 업무일치도를 기준으로 계산됩니다.</div>
        <div className="aiscore-main-top-banner-btns flex-row">
          <button>
            <FontAwesomeIcon icon={faArrowUpFromBracket} className='aiscore-main-top-icon'/>
            <div className="aiscore-main-top-btn-title">이력서를 업로드해보세요</div>
            <div className="aiscore-main-top-btn-file-info">파일형식 : pdf,doc,docx</div>
          </button>
          <button style={{marginLeft:"20px"}} >
            <FontAwesomeIcon icon={faPencil} className='aiscore-main-top-icon'/>
            <div className="aiscore-main-top-btn-title">이력서를 새로 작성할래요</div>
            <div className="aiscore-main-top-btn-file-info">작성 후 pdf로 저장 가능합니다.</div>
          </button>
        </div>
      </div>
      <div className="aiscore-main-bottom-banner-back flex-column">
        <div className="aiscore-main-bottom-banner-color1"></div>
        <div className="aiscore-main-bottom-banner-color2"></div>
        <div className="aiscore-main-bottom-banner-color3"></div>
        <div className="flex-row aiscore-main-bottom-banner-img-box">
          <img src="https://static.wanted.co.kr/images/ai/img-mo.png"/>
          <img src="https://static.wanted.co.kr/images/ai/logo-wantedai.png"/>
        </div>
        <div className="aiscore-main-bottom-banner-text">서류합격률이 높은 포지션을 보여드려요</div>
        <div className="aiscore-main-bottom-banner-sub-text" style={{marginBottom:"45px"}}>원티드 AI 가 추천하는 포지션에 지원하는 경우<br/>일반 지원 대비 서류 합격률이 4배나 높아집니다.</div>
        <div className="aiscore-main-bottom-banner-text">실제 합격 데이터 기반으로 맞춤 추천해드려요</div>
        <div className="aiscore-main-bottom-banner-sub-text">200만 건의 실제 합격 데이터를 머신러닝하여<br/>업로드된 이력서를 분석하고 맞춤 포지션을 추천해드립니다.</div>
      </div>
    </>
  )
}





export {AiscorePage};