import './JobslistPage.css';
import { Link } from "react-router-dom";
import { MainHeader } from '../Common/Common';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';
import { changeHeaderStyle } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCaretDown, faChevronLeft, faChevronRight, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as farBookmark } from '@fortawesome/free-regular-svg-icons'
import { library } from "@fortawesome/fontawesome-svg-core";

import { JobslistContentCardsData } from './JobslistPageData';
import axios from 'axios';

const JobslistPage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(0));
  },[])

  return(
    <>
      <MainHeader/>
      <JobslistHeader/>
      <JobslistMain/>
    </>
  )
}

const JobslistHeader = ()=>{
  return(
    <div className='main-container jobslist-header-back'>
      <div className='flex-row'>
        <div className='jobslist-header-title'>전체</div>
        <button className='jobslist-header-btn'>
          <FontAwesomeIcon icon={faChevronDown}/>
        </button>
        <div className='jobslist-header-divider'></div>
        <div className='jobslist-header-text'>직군을 선택해주세요.</div>
      </div>
    </div>
  )
}

const JobslistMain=()=>{
 
  // 상단 케러샐
  const jobslistCarouselContentBtn = ['연봉이 최고의 복지 💰','재택근무 🏡','퇴사율 10% 이하 🍭','급성장 중 📈','병역특례 🧑🏼',
                                      '50인 이하 ⬇️','50인 이상 ⬆️','업력 5년 이상 🏢','유연근무 🕞','자유로운 휴가 🌴',
                                    '일한만큼 받는 보상 💵','수평적 문화 🙌🏼','요즘 뜨는 산업 😮','식사 간식 제공 ☕']; // 14개
  const jobslistCarouselContentBtnColor = ["rgb(242, 251, 245)","rgb(243, 249, 254)",'rgb(243, 242, 251)','rgb(246, 248, 238)','rgb(247, 242, 249)',
                                          "rgb(242, 251, 245)","rgb(243, 249, 254)",'rgb(243, 242, 251)','rgb(246, 248, 238)','rgb(247, 242, 249)',
                                          "rgb(242, 251, 245)","rgb(243, 249, 254)",'rgb(243, 242, 251)','rgb(246, 248, 238)']
  const [jobslistCarouselBtn, setJobslistcarouselBtn] = useState(["none","flex"]);

  // 북마크
  library.add(farBookmark);


  // 이동할 px
  const [JobslistCarouselpx, setJobslistCarouselpx] = useState(0); 

  // 버튼으로 이동하기
  const CarouselBtnFunc = (type)=>{
    if(JobslistCarouselpx==0 && type==1){
      setJobslistCarouselpx(-400);
      setJobslistcarouselBtn(["flex","flex"]);
    } if(JobslistCarouselpx==-400 && type==1){
      setJobslistCarouselpx(-800);
      setJobslistcarouselBtn(["flex","none"]);
    } 
    if(JobslistCarouselpx==-800 && type==-1){
      setJobslistCarouselpx(-400);
      setJobslistcarouselBtn(["flex","flex"]);
    } if(JobslistCarouselpx==-400 && type==-1){
      setJobslistCarouselpx(0);
      setJobslistcarouselBtn(["none","flex"]);
    } 
  }

  // 어떤 버튼눌렀을떄 스타일 변경
  const [JobslistCarouselBtnStyle, setJobslistcarouselBtnStyle] = useState(['jobslist-not-click','jobslist-not-click','jobslist-not-click','jobslist-not-click','jobslist-not-click'
  ,'jobslist-not-click','jobslist-not-click','jobslist-not-click','jobslist-not-click','jobslist-not-click'
  ,'jobslist-not-click','jobslist-not-click','jobslist-not-click','jobslist-not-click']);



  return(
    <div className='jobslist-main-back main-container'>

      <div className='jobslist-main-sticky-banner'>
        <div className='jobslist-main-select-banner flex-row'>
          <div className='flex-row'>
            <button className='flex-row jobslist-main-area-btn'>
              <span>지역</span>
              <span>한국</span>
              <div className='jobslist-main-area-btn-num'>1</div>
            </button>
            <button className='flex-row jobslist-main-area-btn'>
              <span>경력</span>
              <span>전체</span>
              <span style={{marginLeft:"10px"}}><FontAwesomeIcon icon={faCaretDown}/></span>
            </button>
            <button className='flex-row jobslist-main-area-btn'>
              <span>기술스택</span>
              <span style={{color:"#000000"}}><FontAwesomeIcon icon={faCaretDown}/></span>
            </button>
          </div>
          <button className='flex-row jobslist-main-area-btn'>
              <span>응답률순</span>
              <span style={{color:"#000000"}}><FontAwesomeIcon icon={faCaretDown}/></span>
          </button>
        </div>
        
        <div className='jobslist-main-divider'></div>
      
        <div className="jobslist-carousel-back flex-row">
          <div className="jobslist-carousel-box">
            <div className="jobslist-carousel-contents" style={{transform:`translateX(${JobslistCarouselpx}px)`}}>
              {jobslistCarouselContentBtn.map((data,index)=>{
                return(
                  <button key={index} className={`jobslist-carousel-content-btn ${JobslistCarouselBtnStyle[index]}`}
                    style={{backgroundColor:`${jobslistCarouselContentBtnColor[index]}`}}
                    onClick={()=>{
                      let currentBtn = [...JobslistCarouselBtnStyle];
                      if(currentBtn[index] == 'jobslist-click'){
                        currentBtn[index] = 'jobslist-not-click';
                      } else if(currentBtn[index] == 'jobslist-not-click'){
                        currentBtn[index] = 'jobslist-click';
                      }
                      setJobslistcarouselBtnStyle(currentBtn);
                    }} >
                    {data}
                  </button>  
                )
              })}
            </div>
            <div className="jobslist-carousel-move-btn-box move-btn-left-box" style={{display:`${jobslistCarouselBtn[0]}`}}>
              <button className="jobslist-carousel-move-btn" onClick={()=>{ CarouselBtnFunc(-1); }}>
                <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
            </div>
            <div className="jobslist-carousel-move-btn-box move-btn-right-box" style={{display:`${jobslistCarouselBtn[1]}`}}>
              <button className="jobslist-carousel-move-btn" onClick={()=>{ CarouselBtnFunc(+1); }}>
                <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            </div>
          </div>
        </div>

        <div className='jobslist-main-divider__2'></div>
      </div>

      <div className='jobslist-main-contents'>
        <div className='jobslist-main-contents-bookmark-text'>
          <FontAwesomeIcon icon={faBookmark} className='icon-style' style={{height:"16px"}} />
          <span>북마크 모아보기</span>
          <FontAwesomeIcon icon={faChevronRight} className='icon-style' style={{height:"12px"}}/>
        </div>

        <JobslistContentCards/>

      </div>

    </div>
  )
}


const JobslistContentCards=()=>{

  library.add(farBookmark);

  // 필터링 없는 채용 리스트 담을 state
  const [ApplyList, setApplyList] = useState('');

  // 카드 데이터 불러오기
  useEffect( ()=>{
    const url = "https://prod.risingserver13forever.shop/app/recruitments";
    try{
      axios.get(url)
      .then((res)=>{
        // console.log(res.data.result);
        setApplyList(res.data.result);
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  },[])


  // 북마크 선택시 변할 버튼 스타일을 위한 state
  const [BookMarkBtnIndex, setBookMarkBtnIndex] = useState([]);
  // 내 아이디에서 북마크 불러오기
  useEffect( ()=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        // console.log(res.data.result);

        let bookmarkBtnIndexArr = [];
        res.data.result.map((data)=>{ bookmarkBtnIndexArr.push(data.recruitmentPostId); }) 
        setBookMarkBtnIndex(bookmarkBtnIndexArr);  // 현재들어있는 bookmark의 id 를 저장
       
        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  },[])




  // 북마크 클릭시 : 내 북마크 리스트 조회하기
  const ShowMyBookMarkList = (PostId)=>{

    let userid = localStorage.getItem('userid');
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/users/${userid}/bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.get(url, {headers:config})
      .then((res)=>{
        // console.log(res.data);
        
        // 현재 누른 포스트의 id가 내 bookmark에 저장된 포스트의 id에 포함되는지 찾기
        let findBookList = res.data.result.find( function(data){ return data.recruitmentPostId === PostId } );
        // 포함되어있다면 삭제 , 포함되어 있지 않다면 추가 
        if (findBookList !== undefined){
          DeleteMyBookMarkList(PostId);
          // console.log("있는데요");
        } else if(findBookList === undefined) {
          AddMyBookMarkList(PostId);
          // console.log("없는데요");
        }

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }

  };

  // 북마크 클릭시 : 내 북마크 리스트에 추가하기
  const AddMyBookMarkList = (PostId)=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${PostId}/plus-bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.post(url,{},{headers:config})
      .then((res)=>{
        // 내 리스트에 추가했을때 빨간색 북마크로 표시해주기
        let plusBookMarkBtnIndex = [...BookMarkBtnIndex];
        plusBookMarkBtnIndex.push(PostId);
        setBookMarkBtnIndex( plusBookMarkBtnIndex );  

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  };

  // 북마크 클릭시 : 내 북마크 리스트에서 삭제하기
  const DeleteMyBookMarkList = (PostId)=>{
    let jwtValue = localStorage.getItem('jwt');

    const url = `https://prod.risingserver13forever.shop/app/recruitments/${PostId}/minus-bookmarks`;
    const config = { "x-access-token": jwtValue };

    try{
      axios.patch(url,{},{headers:config})
      .then((res)=>{
        let minusBookMarkBtnIndex = [...BookMarkBtnIndex];
        // minusBookMarkBtnIndex.push(PostId);
        let filterdminusBookMarkBtnIndex = minusBookMarkBtnIndex.filter((element)=>element !== PostId );

        setBookMarkBtnIndex( filterdminusBookMarkBtnIndex );  

        })
      .catch((err)=>{
        console.log(err);
      })
    }
    catch(err){
      alert(err);
    }
  }




  // 무한스크롤 만들어보자

  // 밑에 추가될 데이터 개수 변경시킬 state
  const [plusPageIndex, setPlusPageIndex] = useState(0);
  // const [plusPageIndex, setPlusPageIndex] = useState([]);

  // 보여지면 함수를 실행시킬 target 지정
  const [target, setTarget] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   console.log(plusPageIndex);
  // }, [plusPageIndex]);


  // // 카드 데이터 불러오기
  // const getCardData = ()=>{
  //   const url = "https://prod.risingserver13forever.shop/app/recruitments";
  //   try{
  //     axios.get(url)
  //     .then((res)=>{
  //       console.log(res.data.result);
  //       setPlusPageIndex(prev=>[...prev, ...res.data.result])
  //       })
  //     .catch((err)=>{
  //       console.log(err);
  //     })
  //   }
  //   catch(err){
  //     alert(err);
  //   }
  // }


  const getMoreItem = async () => {
    setIsLoaded(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // 여기에 원하는 효과를 집어넣으면됨
    // 처음 한번은 무조건 실행이되고, set~~ 함수안에 input => output으로 표현해야됨,, 왜지..?
    setPlusPageIndex(prev => prev+12);

    // get 사용해서 불러오기 
    // getCardData();

    setIsLoaded(false);
  };

  const onIntersect = async ([entry], observer) => {
    if (entry.isIntersecting && !isLoaded) {
      observer.unobserve(entry.target);
      await getMoreItem();
      observer.observe(entry.target);
    }
  };

  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, {
        threshold: 0.4,
      });
      observer.observe(target);
    }
    return () => observer && observer.disconnect();
  }, [target]);






  return(
    <div className="jobslist-new-position-back">
      <div className="jobslist-new-position-contents">
        {
          ApplyList!=''?

          ApplyList.slice(0,12).map((data)=>{
            return(
              <Link key={data.recruitmentPostId} to={`/jobdetail/${data.recruitmentPostId}`}>
                <div className="jobslist-new-position-card">
                  <img src={data.companyRepresentativeImg}/>
                  <div>{data.recruitmentPositionName}</div>
                  <div>{data.companyName}</div>
                  <div>{data.country} </div>
                  <div>채용보상금 1,000,000원</div>
                  {
                    BookMarkBtnIndex.includes(data.recruitmentPostId) ?
                    <button className="jobslist-bookmark-btn" style={{color:"red"}}
                      onClick={(e)=>{
                        e.preventDefault();
                        // 내 북마크 리스트 조회 -> 삭제 or 추가
                        ShowMyBookMarkList(data.recruitmentPostId);
                      }}>
                      <FontAwesomeIcon icon={faBookmark}/>
                    </button>
                    : 
                    <button className="jobslist-bookmark-btn"
                      onClick={(e)=>{
                        e.preventDefault();
                        // 내 북마크 리스트 조회 -> 삭제 or 추가
                        ShowMyBookMarkList(data.recruitmentPostId);
                      }}>
                      <FontAwesomeIcon icon={farBookmark}/>
                    </button>
                  }
                </div>
              </Link>
            )
          })
          : null
        }
      </div>
      <div className='jobslist-middle-banner'>
          <div>내 유형과 가장 어울리는 회사는?</div>
          <div>유형테스트 하러가기 <FontAwesomeIcon icon={faChevronRight}/></div>
      </div>
      <div className="jobslist-new-position-contents">
        {
          ApplyList!=''?

          ApplyList.slice(0,plusPageIndex).map((data)=>{
            return(
              <Link key={data.recruitmentPostId} to={`/jobdetail/${data.recruitmentPostId}`}>
                <div className="jobslist-new-position-card">
                  <img src={data.companyRepresentativeImg}/>
                  <div>{data.recruitmentPositionName}</div>
                  <div>{data.companyName}</div>
                  <div>{data.country} </div>
                  <div>채용보상금 1,000,000원</div>
                  {
                    BookMarkBtnIndex.includes(data.recruitmentPostId) ?
                    <button className="jobslist-bookmark-btn" style={{color:"red"}}
                      onClick={(e)=>{
                        e.preventDefault();
                        // 내 북마크 리스트 조회 -> 삭제 or 추가
                        ShowMyBookMarkList(data.recruitmentPostId);
                      }}>
                      <FontAwesomeIcon icon={faBookmark}/>
                    </button>
                    : 
                    <button className="jobslist-bookmark-btn"
                      onClick={(e)=>{
                        e.preventDefault();
                        // 내 북마크 리스트 조회 -> 삭제 or 추가
                        ShowMyBookMarkList(data.recruitmentPostId);
                      }}>
                      <FontAwesomeIcon icon={farBookmark}/>
                    </button>
                  }
                </div>
              </Link>
            )
          })
          : null
        }
        <div className='plus-page-loading-box' ref={setTarget}></div>
      </div>
      {
        isLoaded?
        <div style={{display:"flex", justifyContent:"center"}} ><img src='https://gifburg.com/images/gifs/loading/webp/0012.webp'/></div>
        :null
      }
    </div>
  )
}









export {JobslistPage};

