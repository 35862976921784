import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeHeaderStyle } from "../../store/store";
import { MainFooter, MainHeader } from "../Common/Common";

import './ResumePage.css';

const ResumePage = ()=>{

  // 헤더 메뉴바 스타일 변경
  let dispatch =  useDispatch();
  useEffect(()=>{
    dispatch(changeHeaderStyle(4));
  },[])


  return(
    <>
      <MainHeader/>
      <ResumeTopBanner/>
      <ResumeLikeBanner/>
      <ResumeWriteBanner/>
      <ResumeRecommendBanner/>
      <MainFooter/>
    </>
  )
}

const ResumeTopBanner =()=>{
  return(
    <div className="resume-top-banner-back">
      <div className="resume-top-banner-contents main-container">
        <div>합격을 부르는 이력서</div>
        <div>원티드 이력서로 지원 시<br/>일반 이력서보다 서류 합격률이 2배 높아집니다.</div>
        <div className="resume-top-banner-btns-box">
          <button>이력서 관리</button>
          <button>새 이력서 작성</button>
        </div>
      </div>
    </div>
  )
}

const ResumeLikeBanner =()=>{
  return(
    <div className="resume-like-banner-back main-container">
      <div className="resume-like-banner-title">원티드 이력서는 이런점이 좋아요!</div>
      <div className="resume-like-banner-contents-box">
        <div className="resume-like-banner-card">
          <div className="resume-like-banner-text-box">
            <div>이력서 하나로 모든 준비 끝</div>
            <div>이력서 작성부터 지원까지 한 곳에서! <br/> 회사별 양식에 맞출 필요 없이 하나의 이력서만 작성하세요.</div>
          </div>
          <img className="resume-like-banner-card-img1" src="https://static.wanted.co.kr/images/userweb/resume_intro/resume_advantage_01.png"/>
        </div>
        <div className="resume-like-banner-card">
          <div className="resume-like-banner-text-box">
            <div>완성도를 높여주는 이력서 코칭</div>
            <div>이력서 코칭*으로 서류 합격률 30% UP <br/>  300만 합격 데이터를 분석해 합·불합격 단어를 알려드려요.</div>
          </div>
          <img className="resume-like-banner-card-img2" src="https://static.wanted.co.kr/images/userweb/resume_intro/resume_advantage_02.png"/>
        </div>
        <div className="resume-like-banner-card">
          <div className="resume-like-banner-text-box">
            <div>이력서만 등록해도 찾아오는 면접 제안</div>
            <div>매치업은 서류 전형 없이 바로 면접 진행! <br/> 채용담당자가 직접 면접을 제안합니다.</div>
          </div>
          <img className="resume-like-banner-card-img3" src="https://static.wanted.co.kr/images/userweb/resume_intro/resume_advantage_03.png"/>
        </div>
        <div className="resume-like-banner-card">
          <div className="resume-like-banner-text-box">
            <div>언제 어디서나 자유롭게 작성</div>
            <div>주말엔 노트북, 출퇴근길엔 스마트폰으로. <br/> 자유롭게 작성하고 필요할 땐 PDF로 저장하세요.</div>
          </div>
          <img className="resume-like-banner-card-img4" src="https://static.wanted.co.kr/images/userweb/resume_intro/resume_advantage_04.png"/>
        </div>
      </div>
    </div>
  )
}

const ResumeWriteBanner =()=>{
  return(
    <div className="resume-write-banner-back">
      <div className="main-container">
        <div className="resume-write-banner-title">쓰는 사람도 보는 사람도 편하게</div>
        <div className="resume-write-banner-img-box">
          <img src="/assets/img/resume-write-banner.png"/>
        </div>
      </div>
      <div className="resume-write-banner-btns-box">
          <button>샘플 다운로드</button>
          <button>새 이력서 작성</button>
      </div>
      <div className="resume-write-banner-blur-box"></div>
    </div>
  )
}

const ResumeRecommendBanner=()=>{
  return(
    <div className="resume-recommend-back">
      <div className="main-container resume-recommend-contents-box">
        <div className="resume-recommend-text-box">
          <div className="resume-recommend-text-box-title">
            이력서 완성하면<br/>
            <span><img width={198} height={34} src="https://static.wanted.co.kr/images/userweb/resume_intro/resume_ai_logo.png"/>가 포지션 추천까지</span>
          </div>
          <div className="resume-recommend-text-box-sub-title">
            이력서를 분석하여 딱 맞는 포지션을 찾아드려요. <br/>원티드AI가 추천한 포지션은 서류합격률이 일반 지원 대비 4배 높습니다.
          </div>
          <button className="resume-recommend-text-box-start-btn">지금 시작하기</button>
        </div>
        <img width={516} height={352} style={{marginBottom:"-100px"}} src="https://static.wanted.co.kr/images/userweb/resume_intro/resume_ai_sample.png"/>
      </div>
    </div>
  )
}



export {ResumePage};