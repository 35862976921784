
const CommunityMainContentsCardData = [
  {
    index:0,
    img:"https://static.wanted.co.kr/open_profile/avatar/3aac6514a32ca57e1e9581a38ede33f04710702e8ea59874c0dc5268cabfb93a",
    id:"hannahan",
    job:"경영·비즈니스",
    carrer:"신입",
    time:"4분 전",
    title:"근골격계 통증 관련 설문 하시고 스벅 깊티 받아가세요!",
    text:"안녕하세요! 현재 근골격계 질환/통증과 관련하여 서비스를 준비 중인 아이엠랩(I.M.LAB)에서 일하고 있는 학생 인턴입니다:) 실제 근골격계 질환/통증으로 어려움을 겪고 계신 분들의 목소리를 듣고 더욱 실질적인 도움을 드릴 수 있는 서비스를 개발하고자 간단한 설문을 준비해보았습니다.",
    tags:["회사생활"],
    like:0,
    comment:0,
  },
  {
    index:1,
    img:"https://graph.facebook.com/960889823977822/picture?height=300&width=300",
    id:"마케터초인",
    job:"마케팅·광고",
    carrer:"10년차",
    time:"2023.02.05",
    title:"마케터는 대체 뭘 만드는 사람일까?",
    text:`지금의 마케팅이 잘 안되는 이유
마케팅을 해야 하는 사람, 더 나아가 마케터와 함께 일을 하는 모든 사람이 알아야 할 이야기
    
    마케터는 누구일까?
    마케터는 뭘 만드는 사람일까?
    마케터는 무엇의 전문가일까?`,
    tags:["커리어고민","마케팅","브랜딩"],
    like:3,
    comment:0,
  },
  {
    index:2,
    img:"https://static.wanted.co.kr/open_profile/avatar/fd3cbaf78fa22c2be83acfb9ecdabb354d45f10ded99ac8bdd9b5d73e10c9f63",
    id:"tommy",
    job:"",
    carrer:"",
    time:"2023.02.04",
    title:"초기창업 멤버(딥러닝 엔지니어)를 구합니다.",
    text:`안녕하세요. 창업준비 5개월차 대표입니다.

    초기창업 멤버를 찾고 싶어서 이렇게 글을 적습니다.
    
    저희는 최첨단 기술을 활용한 AIaaS Platform으로써
    저비용으로 안전하게 취약계층의 성장을 지원합니다.`,
    tags:["커리어고민","데이터","IT/기술"],
    like:2,
    comment:0,
  },
  {
    index:3,
    img:"https://static.wanted.co.kr/open_profile/avatar/3aac6514a32ca57e1e9581a38ede33f04710702e8ea59874c0dc5268cabfb93a",
    id:"hannahan",
    job:"경영·비즈니스",
    carrer:"신입",
    time:"4분 전",
    title:"근골격계 통증 관련 설문 하시고 스벅 깊티 받아가세요!",
    text:"안녕하세요! 현재 근골격계 질환/통증과 관련하여 서비스를 준비 중인 아이엠랩(I.M.LAB)에서 일하고 있는 학생 인턴입니다:) 실제 근골격계 질환/통증으로 어려움을 겪고 계신 분들의 목소리를 듣고 더욱 실질적인 도움을 드릴 수 있는 서비스를 개발하고자 간단한 설문을 준비해보았습니다.",
    tags:["회사생활"],
    like:0,
    comment:0,
  },
  {
    index:4,
    img:"https://graph.facebook.com/960889823977822/picture?height=300&width=300",
    id:"마케터초인",
    job:"마케팅·광고",
    carrer:"10년차",
    time:"2023.02.05",
    title:"마케터는 대체 뭘 만드는 사람일까?",
    text:`지금의 마케팅이 잘 안되는 이유
마케팅을 해야 하는 사람, 더 나아가 마케터와 함께 일을 하는 모든 사람이 알아야 할 이야기
    
    마케터는 누구일까?
    마케터는 뭘 만드는 사람일까?
    마케터는 무엇의 전문가일까?`,
    tags:["커리어고민","마케팅","브랜딩"],
    like:3,
    comment:0,
  },
  {
    index:5,
    img:"https://static.wanted.co.kr/open_profile/avatar/fd3cbaf78fa22c2be83acfb9ecdabb354d45f10ded99ac8bdd9b5d73e10c9f63",
    id:"tommy",
    job:"",
    carrer:"",
    time:"2023.02.04",
    title:"초기창업 멤버(딥러닝 엔지니어)를 구합니다.",
    text:`안녕하세요. 창업준비 5개월차 대표입니다.

    초기창업 멤버를 찾고 싶어서 이렇게 글을 적습니다.
    
    저희는 최첨단 기술을 활용한 AIaaS Platform으로써
    저비용으로 안전하게 취약계층의 성장을 지원합니다.`,
    tags:["커리어고민","데이터","IT/기술"],
    like:2,
    comment:0,
  },
  {
    index:6,
    img:"https://static.wanted.co.kr/open_profile/avatar/3aac6514a32ca57e1e9581a38ede33f04710702e8ea59874c0dc5268cabfb93a",
    id:"hannahan",
    job:"경영·비즈니스",
    carrer:"신입",
    time:"4분 전",
    title:"근골격계 통증 관련 설문 하시고 스벅 깊티 받아가세요!",
    text:"안녕하세요! 현재 근골격계 질환/통증과 관련하여 서비스를 준비 중인 아이엠랩(I.M.LAB)에서 일하고 있는 학생 인턴입니다:) 실제 근골격계 질환/통증으로 어려움을 겪고 계신 분들의 목소리를 듣고 더욱 실질적인 도움을 드릴 수 있는 서비스를 개발하고자 간단한 설문을 준비해보았습니다.",
    tags:["회사생활"],
    like:0,
    comment:0,
  },
  {
    index:7,
    img:"https://graph.facebook.com/960889823977822/picture?height=300&width=300",
    id:"마케터초인",
    job:"마케팅·광고",
    carrer:"10년차",
    time:"2023.02.05",
    title:"마케터는 대체 뭘 만드는 사람일까?",
    text:`지금의 마케팅이 잘 안되는 이유
마케팅을 해야 하는 사람, 더 나아가 마케터와 함께 일을 하는 모든 사람이 알아야 할 이야기
    
    마케터는 누구일까?
    마케터는 뭘 만드는 사람일까?
    마케터는 무엇의 전문가일까?`,
    tags:["커리어고민","마케팅","브랜딩"],
    like:3,
    comment:0,
  },
  {
    index:8,
    img:"https://static.wanted.co.kr/open_profile/avatar/fd3cbaf78fa22c2be83acfb9ecdabb354d45f10ded99ac8bdd9b5d73e10c9f63",
    id:"tommy",
    job:"",
    carrer:"",
    time:"2023.02.04",
    title:"초기창업 멤버(딥러닝 엔지니어)를 구합니다.",
    text:`안녕하세요. 창업준비 5개월차 대표입니다.

    초기창업 멤버를 찾고 싶어서 이렇게 글을 적습니다.
    
    저희는 최첨단 기술을 활용한 AIaaS Platform으로써
    저비용으로 안전하게 취약계층의 성장을 지원합니다.`,
    tags:["커리어고민","데이터","IT/기술"],
    like:2,
    comment:0,
  },
]


// {
//   index:,
//   img:"",
//   id:"",
//   job:"",
//   carrer:"",
//   time:"",
//   title:"",
//   text:"",
//   tags:"",
//   like:"",
//   comment:"",
// },

export {CommunityMainContentsCardData};


