import { configureStore, createSlice } from '@reduxjs/toolkit'


// 메인 헤더 네브바 
let mainHeaderStyle = createSlice({
  name: 'mainHeaderStyle',
  initialState: ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'],
  reducers:{
    changeHeaderStyle(state, action){
      if(action.payload===0){
        return ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff']
      } else{
        let changeArr = ['#fff','#fff','#fff','#fff','#fff','#fff','#fff','#fff'];
        changeArr[action.payload] = '#258bf7';
        // console.log(changeArr);
        return changeArr
      }
    }
  }
})

// 로그인, 회원가입 이메일
let loginInfo = createSlice({
  name:'loginInfo',
  initialState:'',
  reducers:{
    changeEmail(state, action){
      return action.payload
    }
  }
})

// 검색모달창
let SearchModal = createSlice({
  name:'SearchModel',
  initialState:'none',
  reducers:{
    ShowSearchModal(state, action){
      // console.log(action.payload);
      return action.payload
    }
  }
})




export default configureStore({
  reducer: { 
    mainHeaderStyle:mainHeaderStyle.reducer,
    loginInfo:loginInfo.reducer,
    SearchModal:SearchModal.reducer
  }
})

export let {changeHeaderStyle} = mainHeaderStyle.actions;
export let {changeEmail} = loginInfo.actions;
export let {ShowSearchModal} = SearchModal.actions;


