import './App.css';
import { Routes, Route } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { LoginPage, PasswordPage, SignUpPage } from './pages/LoginPage/LoginPage';
import { JobsfeedPage } from './pages/JobsfeedPage/JobsfeedPage';
import { JobslistPage } from './pages/JobsfeedPage/JobslistPage';
import { EventPage } from './pages/EventPage/EventPage';
import { SalaryPage } from './pages/SalaryPage/SalaryPage';
import { ResumePage } from './pages/ResumePage/ResumePage';
import { ResumePageLogin } from './pages/ResumePage/ResumePageLogin';
import { CommunityPage } from './pages/CommunityPage/CommunityPage';
import { AiscorePage } from './pages/AiscorePage/AiscorePage';
import { MyBookMarkPage } from './pages/MyPage/MyBookMarkPage';
import { JobDetailPage } from './pages/JobsfeedPage/JobDetailPage';


function App() {
  return (
    <>
      
      <Routes>
        <Route path='/' element={<MainPage/>} />
        <Route path='/login' element={<LoginPage/>} />
        <Route path='/signup' element={<SignUpPage/>} />
        <Route path='/jobsfeed' element={<JobsfeedPage/>} />
        <Route path='/jobslist' element={<JobslistPage/>}/>
        <Route path='/jobdetail/:id' element={<JobDetailPage/>}/>
        <Route path='/password' element={<PasswordPage/>} />
        <Route path='/events' element={<EventPage/>} />
        <Route path='/salary' element={<SalaryPage/>} />
        <Route path='/resume' element={<ResumePage/>} />
        <Route path='/resume/list' element={<ResumePageLogin/>} />
        <Route path='/community' element={<CommunityPage/>} />
        <Route path='/aiscore' element={<AiscorePage/>} />
        <Route path='/bookmarks' element={<MyBookMarkPage/>} />
      </Routes>

    </>
  );
}

export default App;
